.site-navbar {
  background-color: #3f51b5;
}
.site-navbar .navbar-header {
  background-color: transparent;
  color: #ffffff;
}
.site-navbar .navbar-header .navbar-toggle {
  color: #ffffff;
}
.site-navbar .navbar-header .hamburger:before,
.site-navbar .navbar-header .hamburger:after,
.site-navbar .navbar-header .hamburger .hamburger-bar {
  background-color: #ffffff;
}
.site-navbar .navbar-header .navbar-brand {
  float: none;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.site-navbar .navbar-header .navbar-brand-logo {
  height: 22px;
  transition: height .25s;
}
.site-navbar .navbar-header .navbar-brand-text {
  display: none;
}
@media (min-width: 768px) {
  .site-navbar .navbar-header {
    width: 65px;
  }
}
.site-navbar .navbar-container {
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .site-navbar .navbar-container {
    margin-left: 65px;
    transition: margin-left 0.25s;
  }
}
.site-navbar.navbar-inverse .navbar-container {
  background-color: transparent;
}
html,
body {
  height: 100%;
}
body {
  padding-top: 66px;
}
.site-menubar {
  z-index: 1400;
  position: fixed;
  top: 66px;
  height: 100%;
  height: -webkit-calc(100% - 66px);
  height: calc(100% - 66px);
  width: 260px;
  background: #ffffff;
  color: rgba(117, 117, 117, 0.9);
  font-family: "Roboto", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: width .25s, opacity .25s, transform .25s;
}
.site-menubar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.site-menubar a {
  outline: none;
}
.site-menubar a:hover,
.site-menubar a:focus {
  text-decoration: none;
}
.site-menubar .scrollable-inverse.scrollable .scrollable-bar-handle {
  background: rgba(158, 158, 158, 0.6);
}
.site-menubar.site-menubar-dark {
  background: #212121;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
.site-menubar-footer {
  position: fixed;
  bottom: 0;
  display: block;
  height: 65px;
  background-color: #e0e0e0;
  z-index: 1400;
  transition: width .25s;
}
.site-menubar-footer > a {
  position: relative;
  display: inline-block;
  width: 33.33333333%;
  height: 65px;
  padding: 23px 0;
  color: #757575;
  font-size: 14px;
  text-align: center;
  background-color: #e0e0e0;
  transition: left .25s, width .25s;
}
.site-menubar-footer > a:hover,
.site-menubar-footer > a:focus {
  background-color: #d3d3d3;
}
.site-menubar-dark .site-menubar-footer {
  background-color: #21292e;
}
.site-menubar-dark .site-menubar-footer > a {
  background-color: #21292e;
}
.site-menubar-dark .site-menubar-footer > a:hover,
.site-menubar-dark .site-menubar-footer > a:focus {
  background-color: #1e2427;
}
.site-menu {
  font-size: 14px;
  overflow: visible;
  padding-bottom: 20px;
  transition: transform .25s;
}
.site-menu-item {
  transition: transform .1s, all .25s, border 0;
  line-height: 36px;
}
.site-menu-item > a {
  white-space: nowrap;
  cursor: pointer;
}
.site-menu-icon {
  display: inline-block;
  margin-right: 16px;
  width: 1em;
  text-align: center;
}
.site-menu-title {
  display: inline-block;
  max-width: 160px;
  vertical-align: middle;
  transition: visibility .25s,opacity .25s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.site-menu-title:first-child {
  margin-left: 25px;
}
.site-menu-arrow {
  font-family: 'Material-Design-Iconic-Font';
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.site-menu-arrow:before {
  content: '\f2fb';
}
.site-menu-arrow,
.site-menu-badge,
.site-menu-label {
  position: absolute;
  right: 25px;
  display: inline-block;
  vertical-align: middle;
  transition: visibility cubic-bezier(0.76, 0.1, 1, -0.16) 0.25s, opacity cubic-bezier(0.76, 0.1, 1, -0.16) 0.5s;
}
.site-menu .badge,
.site-menu .label {
  padding: 2px 5px 3px;
  font-size: 12px;
  font-weight: 300;
}
.site-menu-item {
  position: relative;
  white-space: nowrap;
}
.site-menu-item a {
  display: block;
  color: rgba(117, 117, 117, 0.9);
}
.site-menu-item:hover > a {
  color: #424242;
  background-color: rgba(33, 33, 33, 0.05);
}
.site-menu-item.active {
  background: rgba(33, 33, 33, 0.05);
}
.site-menu-item.active > a {
  color: #424242;
  background: transparent;
}
.site-menu-item.active.hover > a {
  background: transparent;
}
.site-menu > .site-menu-item {
  font-size: 14px;
  padding: 0;
}
.site-menu > .site-menu-item > a {
  padding: 0 25px;
  line-height: 46px;
}
.site-menu > .site-menu-item.active {
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.site-menu-item > .site-menu-sub {
  background-color: rgba(33, 33, 33, 0.05);
}
.site-menubar-dark .site-menu-item a {
  color: rgba(158, 158, 158, 0.9);
}
.site-menubar-dark .site-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.02);
}
.site-menubar-dark .site-menu-item:hover > a {
  color: rgba(255, 255, 255, 0.8);
  background: transparent;
}
.site-menubar-dark .site-menu-item.active {
  background: #1e1e1e;
}
.site-menubar-dark .site-menu-item.active > a {
  color: #ffffff;
}
.site-menubar-dark .site-menu-item > .site-menu-sub {
  background-color: #1e1e1e;
}
.site-gridmenu {
  position: fixed;
  top: 66px;
  bottom: 0;
  padding: 20px 0;
  background-color: #212121;
  z-index: 1500;
  opacity: 0;
  visibility: hidden;
}
.js > .site-gridmenu {
  transition: opacity .5s ease 0s, visibility 0s;
}
.site-gridmenu-active > .site-gridmenu {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease 0s;
}
.site-gridmenu-active > .site-gridmenu ul {
  opacity: 1;
  transform: rotateX(0deg);
}
.site-gridmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: .4;
  transform: translateY(-25%) rotateX(35deg);
  transition: transform .5s ease 0s, opacity .5s ease 0s;
}
.site-gridmenu li {
  float: left;
  width: 50%;
  text-align: center;
}
.site-gridmenu li > a {
  display: block;
  padding: 30px 15px;
  color: #9e9e9e;
}
.site-gridmenu li .icon {
  display: block;
  margin-bottom: 10px;
  font-size: 32px;
}
.site-gridmenu li:hover > a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.02);
}
body.site-gridmenu-active {
  overflow: hidden;
}
.site-gridmenu-toggle:after {
  opacity: 0;
  font-family: 'Material-Design-Iconic-Font';
  font-size: 14px;
  transition: opacity 0.15s;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  position: relative;
  right: -3px;
  top: -1px;
  content: '\f2f9';
}
.site-gridmenu-active .site-gridmenu-toggle:after {
  opacity: 1;
}
.site-sidebar {
  z-index: 1210;
}
.site-sidebar.slidePanel {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.site-sidebar.slidePanel.slidePanel-right,
.site-sidebar.slidePanel.slidePanel-left {
  width: 300px;
  overflow: hidden;
}
.site-sidebar-content {
  height: 100%;
}
.site-sidebar-nav.nav-tabs > li {
  display: table-cell;
  width: 1%;
}
.site-sidebar-nav.nav-tabs > li > a {
  font-size: 16px;
  line-height: 90px;
  padding: 0;
}
.site-sidebar-tab-content {
  height: 100%;
  height: -webkit-calc(100% - 90px);
  height: calc(100% - 90px);
}
.site-sidebar-tab-content > .tab-pane {
  height: 100%;
  padding: 20px 30px;
}
.site-sidebar-tab-content > .tab-pane.scrollable {
  padding: 0;
}
.site-sidebar-tab-content > .tab-pane .scrollable-content {
  padding: 20px 30px;
}
.site-sidebar-tab-content > .tab-pane .list-group {
  margin-left: -30px;
  margin-right: -30px;
}
.site-sidebar-tab-content > .tab-pane .list-group > .list-group-item {
  padding-left: 30px;
  padding-right: 30px;
}
.site-sidebar .conversation {
  position: absolute;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 1700;
  transition: all .3s;
}
.site-sidebar .conversation.active {
  right: 0;
  overflow-y: auto;
}
.site-sidebar .conversation-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}
.site-sidebar .conversation-header > * {
  margin: 0;
  padding: 0 30px;
  line-height: 90px;
}
.site-sidebar .conversation-return {
  position: relative;
  z-index: 1;
  cursor: pointer;
  color: rgba(66, 66, 66, 0.4);
}
.site-sidebar .conversation-return:hover,
.site-sidebar .conversation-return:focus {
  color: rgba(66, 66, 66, 0.6);
}
.site-sidebar .conversation-return:active {
  color: #424242;
}
.site-sidebar .conversation-title {
  position: relative;
  top: 1px;
  color: #424242;
  text-align: right;
}
.site-sidebar .conversation-content {
  padding: 30px 15px;
}
.site-sidebar .conversation-reply {
  position: fixed;
  bottom: 0;
  padding: 10px 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
}
.site-sidebar .conversation-reply .form-control {
  border: none;
  border-right: 1px solid #e0e0e0;
}
.site-sidebar .conversation .chats {
  position: relative;
  top: 90px;
}
@media (max-width: 767px) {
  .site-sidebar.slidePanel.slidePanel-right,
  .site-sidebar.slidePanel.slidePanel-left {
    width: 100%;
  }
}
.site-action {
  position: fixed;
  z-index: 1290;
  bottom: 55px;
  right: 32px;
  animation-duration: 3s;
}
.site-action input {
  display: none;
}
.site-action .btn {
  box-shadow: 0 10px 10px 0 rgba(60, 60, 60, 0.1);
}
.site-action .front-icon {
  display: block;
}
.site-action .back-icon {
  display: none;
}
.site-action-buttons {
  display: none;
  position: absolute;
  bottom: 56px;
  left: 0;
  width: 100%;
  text-align: center;
}
.site-action-buttons .btn {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  animation-delay: 100ms;
}
.site-action.active .front-icon {
  display: none;
}
.site-action.active .back-icon {
  display: block;
}
.site-action.active .site-action-buttons {
  display: block;
}
@media (max-width: 767px) {
  .site-action .btn-floating {
    width: 46px;
    height: 46px;
    padding: 0;
    font-size: 16px;
    box-shadow: 0 6px 6px 0 rgba(60, 60, 60, 0.1);
  }
  .site-action-buttons {
    bottom: 46px;
  }
}
.page {
  background: #f3f4f5;
  position: relative;
  min-height: calc(100% - 44px);
}
.page-dark.layout-full {
  color: #ffffff;
}
.page-dark.layout-full:before {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  z-index: -1;
}
.page-dark.layout-full:after {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.6);
  z-index: -1;
}
.page-dark.layout-full .brand {
  margin-bottom: 22px;
}
.page-dark.layout-full .brand-text {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}
.page-nav-tabs {
  padding: 0 30px;
}
.page-content {
  padding: 30px 30px;
}
.page-content-actions {
  padding: 0 30px 30px;
}
.page-content-actions .dropdown {
  display: inline-block;
}
.page-content-actions:before,
.page-content-actions:after {
  content: " ";
  display: table;
}
.page-content-actions:after {
  clear: both;
}
.page-content-table {
  padding: 0;
  max-width: 100%;
  overflow-x: auto;
}
.page-content-table .table > thead > tr > th,
.page-content-table .table > tbody > tr > th,
.page-content-table .table > thead > tr > td,
.page-content-table .table > tbody > tr > td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.page-content-table .table > thead > tr > th:first-child,
.page-content-table .table > tbody > tr > th:first-child,
.page-content-table .table > thead > tr > td:first-child,
.page-content-table .table > tbody > tr > td:first-child {
  padding-left: 30px;
}
.page-content-table .table > thead > tr > th:last-child,
.page-content-table .table > tbody > tr > th:last-child,
.page-content-table .table > thead > tr > td:last-child,
.page-content-table .table > tbody > tr > td:last-child {
  padding-right: 30px;
}
.page-content-table .table > tbody > tr:hover > td {
  background-color: #eeeeee;
}
.page-content-table .table > tbody > tr > td {
  cursor: pointer;
}
.page-content-table .table > tbody > tr:last-child td {
  border-bottom: 1px solid #e0e0e0;
}
.page-content-table .table.is-indent > thead > tr > th.pre-cell,
.page-content-table .table.is-indent > tbody > tr > th.pre-cell,
.page-content-table .table.is-indent > thead > tr > td.pre-cell,
.page-content-table .table.is-indent > tbody > tr > td.pre-cell,
.page-content-table .table.is-indent > thead > tr > th.suf-cell,
.page-content-table .table.is-indent > tbody > tr > th.suf-cell,
.page-content-table .table.is-indent > thead > tr > td.suf-cell,
.page-content-table .table.is-indent > tbody > tr > td.suf-cell {
  padding: 0;
  border-top: none;
  border-bottom: none;
  width: 30px;
}
.page-content-table .table.is-indent > tbody > tr:first-child td {
  border-top: none;
}
.page-content-table .table.is-indent > tbody > tr:last-child td.pre-cell,
.page-content-table .table.is-indent > tbody > tr:last-child td.suf-cell {
  border-bottom: none;
}
.page-content-table .table.is-indent > tfoot > tr > td {
  border-top: none;
}
.page-content-table .pagination {
  margin-left: 30px;
  margin-right: 30px;
}
.page-copyright {
  margin-top: 60px;
  color: #424242;
  font-size: 12px;
  letter-spacing: 1px;
}
.page-copyright .social a {
  margin: 0 10px;
  text-decoration: none;
}
.page-copyright .social .icon {
  font-size: 16px;
  color: rgba(66, 66, 66, 0.6);
}
.page-copyright .social .icon:hover,
.page-copyright .social .icon:focus {
  color: rgba(66, 66, 66, 0.8);
}
.page-copyright .social .icon.active,
.page-copyright .social .icon:active {
  color: #424242;
}
.page-copyright-inverse {
  color: #ffffff;
}
.page-copyright-inverse .social .icon {
  color: #ffffff;
}
.page-copyright-inverse .social .icon:hover,
.page-copyright-inverse .social .icon:active {
  color: rgba(255, 255, 255, 0.8);
}
.page {
  background: #f3f4f5;
  position: relative;
  min-height: calc(100% - 44px);
}
.page-dark.layout-full {
  color: #ffffff;
}
.page-dark.layout-full:before {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  z-index: -1;
}
.page-dark.layout-full:after {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.6);
  z-index: -1;
}
.page-dark.layout-full .brand {
  margin-bottom: 22px;
}
.page-dark.layout-full .brand-text {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}
.page-nav-tabs {
  padding: 0 30px;
}
.page-content {
  padding: 30px 30px;
}
.page-content-actions {
  padding: 0 30px 30px;
}
.page-content-actions .dropdown {
  display: inline-block;
}
.page-content-actions:before,
.page-content-actions:after {
  content: " ";
  display: table;
}
.page-content-actions:after {
  clear: both;
}
.page-content-table {
  padding: 0;
  max-width: 100%;
  overflow-x: auto;
}
.page-content-table .table > thead > tr > th,
.page-content-table .table > tbody > tr > th,
.page-content-table .table > thead > tr > td,
.page-content-table .table > tbody > tr > td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.page-content-table .table > thead > tr > th:first-child,
.page-content-table .table > tbody > tr > th:first-child,
.page-content-table .table > thead > tr > td:first-child,
.page-content-table .table > tbody > tr > td:first-child {
  padding-left: 30px;
}
.page-content-table .table > thead > tr > th:last-child,
.page-content-table .table > tbody > tr > th:last-child,
.page-content-table .table > thead > tr > td:last-child,
.page-content-table .table > tbody > tr > td:last-child {
  padding-right: 30px;
}
.page-content-table .table > tbody > tr:hover > td {
  background-color: #eeeeee;
}
.page-content-table .table > tbody > tr > td {
  cursor: pointer;
}
.page-content-table .table > tbody > tr:last-child td {
  border-bottom: 1px solid #e0e0e0;
}
.page-content-table .table.is-indent > thead > tr > th.pre-cell,
.page-content-table .table.is-indent > tbody > tr > th.pre-cell,
.page-content-table .table.is-indent > thead > tr > td.pre-cell,
.page-content-table .table.is-indent > tbody > tr > td.pre-cell,
.page-content-table .table.is-indent > thead > tr > th.suf-cell,
.page-content-table .table.is-indent > tbody > tr > th.suf-cell,
.page-content-table .table.is-indent > thead > tr > td.suf-cell,
.page-content-table .table.is-indent > tbody > tr > td.suf-cell {
  padding: 0;
  border-top: none;
  border-bottom: none;
  width: 30px;
}
.page-content-table .table.is-indent > tbody > tr:first-child td {
  border-top: none;
}
.page-content-table .table.is-indent > tbody > tr:last-child td.pre-cell,
.page-content-table .table.is-indent > tbody > tr:last-child td.suf-cell {
  border-bottom: none;
}
.page-content-table .table.is-indent > tfoot > tr > td {
  border-top: none;
}
.page-content-table .pagination {
  margin-left: 30px;
  margin-right: 30px;
}
.page-copyright {
  margin-top: 60px;
  color: #424242;
  font-size: 12px;
  letter-spacing: 1px;
}
.page-copyright .social a {
  margin: 0 10px;
  text-decoration: none;
}
.page-copyright .social .icon {
  font-size: 16px;
  color: rgba(66, 66, 66, 0.6);
}
.page-copyright .social .icon:hover,
.page-copyright .social .icon:focus {
  color: rgba(66, 66, 66, 0.8);
}
.page-copyright .social .icon.active,
.page-copyright .social .icon:active {
  color: #424242;
}
.page-copyright-inverse {
  color: #ffffff;
}
.page-copyright-inverse .social .icon {
  color: #ffffff;
}
.page-copyright-inverse .social .icon:hover,
.page-copyright-inverse .social .icon:active {
  color: rgba(255, 255, 255, 0.8);
}
.page-header + .page-content {
  padding-top: 0;
}
.page-title {
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 0;
}
.page-title > .icon {
  margin-right: 0.3em;
}
.page-description {
  color: #9e9e9e;
}
.page-header {
  padding: 30px 30px;
  margin-top: 0;
  margin-bottom: 0;
  background: transparent;
  position: relative;
  border-bottom: none;
}
.page-header-actions {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}
.page-header-actions .btn-icon {
  margin-left: 6px;
}
.page-header-actions > * {
  margin-bottom: 0;
}
.page-header .breadcrumb {
  padding: 0;
  margin: 0;
}
.page-header-bordered {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  margin-bottom: 30px;
  border-bottom: 1px solid transparent;
}
.page-header-tabs {
  padding-bottom: 0;
}
.page-header-tabs .nav-tabs-line {
  margin-top: 5px;
  border-bottom-color: transparent;
}
.page-header-tabs .nav-tabs-line > li > a {
  padding: 5px 20px;
}
.page-aside {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  border-right: 1px solid #e0e0e0;
  background: #ffffff;
  transition: visibility 0.1s ease, top 0.3s ease, left 0.5s ease;
  overflow-y: hidden;
}
.page-aside + .page-main {
  margin-left: 260px;
}
.page-aside .list-group-item.active,
.page-aside .list-group-item.active:focus,
.page-aside .list-group-item.active:hover {
  z-index: 0;
}
.page-aside-fixed .page-aside {
  position: fixed;
  top: 66px;
  height: calc(100% -  66px);
}
.page-aside-fixed .page-aside-inner {
  overflow-y: scroll;
  height: 100%;
}
.page-aside-fixed .site-footer {
  margin-left: 260px;
}
.page-aside-section {
  position: relative;
}
.page-aside-section:first-child {
  padding-top: 22px;
}
.page-aside-section:last-child {
  margin-bottom: 22px;
}
.page-aside-section:after {
  content: '';
  position: relative;
  display: block;
  margin: 22px;
  border-bottom: 1px solid #e0e0e0;
}
.page-aside-section:last-child:after {
  display: none;
}
.page-aside-switch {
  display: none;
  cursor: pointer;
  position: absolute;
  left: calc(100% - 1px);
  top: calc(50% - 25px);
  background-color: white;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 0 100px 100px 0;
  line-height: 1;
  padding: 15px 8px 15px 4px;
}
.page-aside-switch .md-chevron-right {
  display: inline;
}
.page-aside-switch .md-chevron-left {
  display: none;
}
.page-aside-title {
  padding: 10px 30px;
  margin: 20px 0 10px;
  font-weight: 500;
  color: #616161;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}
.page-aside .list-group {
  margin-bottom: 22px;
}
.page-aside .list-group-item {
  padding: 13px 30px;
  margin-bottom: 1px;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-aside .list-group-item .icon {
  color: #9e9e9e;
}
.page-aside .list-group-item .item-right {
  float: right;
}
.page-aside .list-group-item:hover,
.page-aside .list-group-item:focus {
  background-color: #eeeeee;
  border: none;
  color: #3f51b5;
}
.page-aside .list-group-item:hover > .icon,
.page-aside .list-group-item:focus > .icon {
  color: #3f51b5;
}
.page-aside .list-group-item.active {
  background-color: transparent;
  color: #3f51b5;
}
.page-aside .list-group-item.active > .icon {
  color: #3f51b5;
}
.page-aside .list-group-item.active:hover,
.page-aside .list-group-item.active:focus {
  background-color: #eeeeee;
  border: none;
  color: #3f51b5;
}
.page-aside .list-group-item.active:hover > .icon,
.page-aside .list-group-item.active:focus > .icon {
  color: #3f51b5;
}
.page-aside .list-group.has-actions .list-group-item {
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 36px;
}
.page-aside .list-group.has-actions .list-group-item .list-editable {
  display: none;
  position: relative;
}
.page-aside .list-group.has-actions .list-group-item .list-editable .input-editable-close {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  outline: none;
  -webkit-appearance: none;
}
.page-aside .list-group.has-actions .list-group-item .list-editable .form-group {
  margin: 0;
}
.page-aside .list-group.has-actions .list-group-item .item-actions {
  display: none;
  position: absolute;
  right: 20px;
  top: 6px;
}
.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon {
  padding-left: 2px;
  padding-right: 2px;
  background-color: transparent;
}
.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon:hover .icon {
  color: #3f51b5;
}
.page-aside .list-group.has-actions .list-group-item .item-actions .icon {
  margin: 0;
}
.page-aside .list-group.has-actions .list-group-item:hover .item-right {
  display: none;
}
.page-aside .list-group.has-actions .list-group-item:hover .item-actions {
  display: block;
}
.page-aside .list-group.has-actions .list-group-item:hover .item-actions .icon {
  color: #757575;
}
@media (max-width: 1199px) {
  .page-aside {
    width: 220px;
  }
  .page-aside + .page-main {
    margin-left: 220px;
  }
  .page-aside-fixed .site-footer {
    margin-left: 220px;
  }
}
@media (max-width: 767px) {
  .page-aside {
    position: fixed;
    top: 66px;
    width: 260px;
    left: -260px;
    border-color: transparent;
    z-index: 1100;
    overflow-y: visible;
    height: calc(100% -  66px);
    visibility: visible;
  }
  .site-navbar-collapse-show .page-aside {
    top: 132px;
    height: calc(100% -  132px);
  }
  .site-menubar-changing .page-aside,
  .site-menubar-open .page-aside {
    height: 100%;
  }
  .page-aside .page-aside-inner {
    height: 100%;
    background-color: white;
    border-right: 1px solid #e0e0e0;
  }
  .page-aside.open {
    left: 0;
  }
  .site-menubar-changing .page-aside.open,
  .site-menubar-open .page-aside.open {
    visibility: hidden;
  }
  .page-aside.open .page-aside-switch .md-chevron-right {
    display: none;
  }
  .page-aside.open .page-aside-switch .md-chevron-left {
    display: inline;
  }
  .page-aside + .page-main {
    margin-left: 0;
  }
  .page-aside-fixed .page-aside {
    left: -260px !important;
  }
  .page-aside-fixed .page-aside.open {
    left: 0 !important;
  }
  .page-aside-fixed .site-footer {
    margin-left: 0 !important;
  }
  .page-aside-switch {
    display: block;
  }
}
.site-footer {
  padding: 10px 30px;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid #e0e0e0;
  height: 44px;
}
.site-footer:before,
.site-footer:after {
  content: " ";
  display: table;
}
.site-footer:after {
  clear: both;
}
.site-footer-legal {
  float: left;
}
.site-footer-actions {
  float: right;
}
.site-footer-right {
  float: right;
}
.site-footer .scroll-to-top {
  color: #757575;
}
.site-footer .scroll-to-top,
.site-footer .scroll-to-top:hover,
.site-footer .scroll-to-top:active {
  text-decoration: none;
}
@media (max-width: 480px) {
  .site-footer {
    height: auto;
  }
}
@media (max-width: 480px) {
  .site-footer-legal,
  .site-footer-right,
  .site-footer-actions {
    display: block;
    float: none;
    text-align: center;
  }
}
.site-footer {
  padding: 10px 30px;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid #e0e0e0;
  height: 44px;
}
.site-footer:before,
.site-footer:after {
  content: " ";
  display: table;
}
.site-footer:after {
  clear: both;
}
.site-footer-legal {
  float: left;
}
.site-footer-actions {
  float: right;
}
.site-footer-right {
  float: right;
}
.site-footer .scroll-to-top {
  color: #757575;
}
.site-footer .scroll-to-top,
.site-footer .scroll-to-top:hover,
.site-footer .scroll-to-top:active {
  text-decoration: none;
}
@media (max-width: 480px) {
  .site-footer {
    height: auto;
  }
}
@media (max-width: 480px) {
  .site-footer-legal,
  .site-footer-right,
  .site-footer-actions {
    display: block;
    float: none;
    text-align: center;
  }
}
.layout-full {
  height: 100%;
}
body.layout-full {
  padding-top: 0;
}
.layout-full .page {
  height: 100%;
  margin: 0 !important;
  padding: 0;
  background-color: transparent;
}
.layout-full > .loader {
  margin-left: 0 !important;
}
@media (min-width: 1200px) {
  .layout-boxed {
    background: #e0e0e0;
  }
  .layout-boxed,
  .layout-boxed .site-navbar {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
}
.layout-boxed .slidePanel-right,
.layout-boxed .slidePanel-left {
  top: 0;
  z-index: 1510;
}
.site-print {
  padding-top: 0;
}
.site-print .site-navbar,
.site-print .site-menubar,
.site-print .site-gridmenu,
.site-print .site-footer {
  display: none;
}
.site-print .page {
  margin: 0 !important;
}
.site-menubar-fold .page,
.site-menubar-fold .site-footer {
  margin-left: 65px;
}
.site-menubar-fold.page-aside-fixed .page-aside {
  left: 65px;
}
.site-menubar-fold.page-aside-fixed .site-footer {
  margin-left: 325px;
}
.site-menubar-fold .site-menu > .site-menu-item {
  font-size: 14px;
}
.site-menubar-fold .site-menu > .site-menu-item > a {
  height: 46px;
}
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-title,
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-arrow,
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-badge {
  opacity: 0;
  visibility: hidden;
}
.site-menubar-fold .site-menubar {
  width: 65px;
}
@media (min-width: 768px) {
  .site-menubar-fold.site-menubar-hover .site-navbar .navbar-header {
    width: 260px;
  }
}
@media (min-width: 768px) {
  .site-menubar-fold.site-menubar-hover .site-navbar .navbar-container {
    margin-left: 260px;
  }
}
.site-menubar-fold.site-menubar-hover .site-navbar .navbar-brand {
  float: left;
  text-align: left;
}
.site-menubar-fold.site-menubar-hover .site-navbar .navbar-brand-logo {
  height: 26px;
}
.site-menubar-fold.site-menubar-hover .site-navbar .navbar-brand-text {
  display: inline-block;
}
.site-menubar-fold.site-menubar-hover .site-menubar {
  width: 260px;
}
.site-menubar-fold.site-menubar-hover .site-menu > .site-menu-item > a .site-menu-arrow,
.site-menubar-fold.site-menubar-hover .site-menu > .site-menu-item > a .site-menu-badge,
.site-menubar-fold.site-menubar-hover .site-menu > .site-menu-item > a .site-menu-title {
  opacity: 1;
  visibility: visible;
}
.site-menubar-fold.site-menubar-hover .site-menubar-footer {
  width: 260px;
}
.site-menubar-fold:not(.site-menubar-hover) .mm-panels > .mm-panel {
  -ms-transform: translate(100%, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.site-menubar-fold:not(.site-menubar-hover) .mm-panels > .mm-panel.mm-current:first-child,
.site-menubar-fold:not(.site-menubar-hover) .mm-panels > .mm-panel.mm-subopened:first-child {
  display: block !important;
  -ms-transform: translate(0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.site-menubar-fold .site-gridmenu {
  width: 65px;
}
.site-menubar-fold .site-gridmenu li {
  float: none;
  width: 100%;
}
.site-menubar-fold .site-gridmenu li > a {
  padding: 15px 0;
}
.site-menubar-fold .site-gridmenu-toggle:after {
  display: none;
}
.site-menubar-fold:not(.site-menubar-hover) .site-menubar-footer {
  overflow: hidden;
  width: 65px;
}
.site-menubar-fold:not(.site-menubar-hover) .site-menubar-footer > a {
  width: 65px;
}
.site-menubar-fold.site-menubar-changing .site-menu > .site-menu-item > .site-menu-sub {
  display: none;
}
@media screen and (min-width: 768px) {
  .css-menubar.page-aside-fixed .site-footer {
    margin-left: 285px;
  }
  .css-menubar .site-menu > .site-menu-item {
    font-size: 14px;
  }
  .css-menubar .site-menu > .site-menu-item > a {
    height: 46px;
  }
  .css-menubar .site-menu > .site-menu-item > a .site-menu-title,
  .css-menubar .site-menu > .site-menu-item > a .site-menu-arrow,
  .css-menubar .site-menu > .site-menu-item > a .site-menu-badge {
    opacity: 0;
    visibility: hidden;
  }
  .css-menubar .site-menubar {
    width: 65px;
  }
  .css-menubar .site-gridmenu {
    width: 65px;
  }
  .css-menubar .site-gridmenu li {
    float: none;
    width: 100%;
  }
  .css-menubar .site-gridmenu li > a {
    padding: 15px 0;
  }
  .css-menubar .site-gridmenu-toggle:after {
    display: none;
  }
  .css-menubar:not(.site-menubar-hover) .site-menubar-footer {
    overflow: hidden;
    width: 65px;
  }
  .css-menubar:not(.site-menubar-hover) .site-menubar-footer > a {
    width: 65px;
  }
  .css-menubar .site-menu .site-menu-item:hover > .site-menu-sub {
    position: absolute;
    left: 100%;
    top: 0;
    display: block;
    width: 240px;
  }
  .css-menubar .site-menu .site-menu-sub {
    display: none;
  }
  .site-menubar-fold.page-aside-fixed .site-footer {
    margin-left: 285px;
  }
}
@media screen and (min-width: 1200px) {
  .css-menubar .page,
  .css-menubar .site-footer {
    margin-left: 260px;
    transition: margin-left 0.25s;
  }
  .css-menubar.page-aside-fixed .page-aside {
    left: 260px;
  }
  .css-menubar.page-aside-fixed .site-footer {
    margin-left: 520px;
  }
}
.site-menubar-unfold .page,
.site-menubar-unfold .site-footer {
  margin-left: 260px;
  transition: margin-left 0.25s;
}
.site-menubar-unfold.page-aside-fixed .page-aside {
  left: 260px;
}
.site-menubar-unfold.page-aside-fixed .site-footer {
  margin-left: 520px;
}
@media (min-width: 768px) {
  .site-menubar-unfold .site-navbar .navbar-header {
    width: 260px;
    transition: width .25s;
  }
}
@media (min-width: 768px) {
  .site-menubar-unfold .site-navbar .navbar-container {
    margin-left: 260px;
  }
}
.site-menubar-unfold .site-navbar .navbar-brand {
  float: left;
  text-align: left;
}
.site-menubar-unfold .site-navbar .navbar-brand-logo {
  height: 26px;
}
.site-menubar-unfold .site-navbar .navbar-brand-text {
  display: inline-block;
}
.site-menubar-unfold.site-menubar-native .site-menubar-body {
  overflow-y: scroll;
}
.site-menubar-unfold .site-menubar {
  width: 260px;
}
.site-menubar-unfold .site-gridmenu {
  width: 260px;
}
.site-menubar-unfold .site-menubar-footer {
  width: 260px;
}
.site-menubar-unfold [data-toggle="menubar"] .hamburger-arrow-left {
  transform: rotate(180deg);
}
.site-menubar-unfold [data-toggle="menubar"] .hamburger-arrow-left:before {
  width: .6em;
  transform: translate3d(0.45em, 0.1em, 0) rotate(45deg);
}
.site-menubar-unfold [data-toggle="menubar"] .hamburger-arrow-left .hamburger-bar {
  border-radius: 0.2em;
}
.site-menubar-unfold [data-toggle="menubar"] .hamburger-arrow-left:after {
  width: .6em;
  transform: translate3d(0.45em, -0.1em, 0) rotate(-45deg);
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .site-menubar-unfold.page-aside-fixed .site-footer {
    margin-left: 480px;
  }
}
@media (max-width: 767px) {
  .site-menubar-unfold .page,
  .site-menubar-unfold .site-footer {
    margin-left: auto;
  }
  .site-menubar-open .page,
  .site-menubar-open .site-footer {
    -ms-transform: translate(260px, 0);
    -ms-transform: translate3d(260px, 0, 0);
    -webkit-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
  }
  .site-menubar-changing {
    overflow: hidden;
  }
  .site-menubar-changing .page,
  .site-menubar-changing .site-footer {
    transition: transform .25s;
  }
  .site-gridmenu {
    width: 100% !important;
    background: rgba(33, 33, 33, 0.9);
  }
  .site-menubar-hide .site-menubar,
  .site-menubar-open .site-menubar {
    transition: transform .25s, top .35s, height .35s;
  }
  .site-menubar-open .site-menubar {
    -ms-transform: translate(0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 767px) {
  .site-navbar-collapsing {
    transition: padding-top 0.35s;
  }
  body.site-navbar-collapse-show {
    padding-top: 132px;
  }
  .site-navbar-collapse-show .slidePanel.slidePanel-right,
  .site-navbar-collapse-show .slidePanel.slidePanel-left {
    top: 135px;
    transition: top .35s;
  }
}
body.site-navbar-small {
  padding-top: 60px;
}
.site-navbar-small .site-navbar {
  height: 60px;
  min-height: 60px;
}
.site-navbar-small .site-navbar .navbar-brand {
  height: 60px;
  padding: 19px 20px;
}
.site-navbar-small .site-navbar .navbar-nav {
  margin: 9.5px -15px;
}
@media (min-width: 768px) {
  .site-navbar-small .site-navbar .navbar-nav > li > a {
    padding-top: 19px;
    padding-bottom: 19px;
  }
}
.site-navbar-small .site-navbar .navbar-toggle {
  height: 60px;
  padding: 19px 15px;
}
.site-navbar-small .site-navbar .navbar-toolbar > li > a {
  padding-top: 19px;
  padding-bottom: 19px;
}
.site-navbar-small .site-navbar .navbar-nav > li > a.navbar-avatar,
.site-navbar-small .site-navbar .navbar-toolbar > li > a.navbar-avatar {
  padding-top: 15px;
  padding-bottom: 15px;
}
.site-navbar-small .site-navbar .navbar-search-overlap .form-control {
  height: 60px !important;
}
.site-navbar-small .page-aside-fixed .page-aside {
  top: 60px;
  height: calc(100% -  60px);
}
.site-navbar-small .site-menubar {
  top: 60px;
}
.site-navbar-small .site-gridmenu {
  top: 60px;
}
.site-navbar-small .site-skintools {
  top: 90px;
}
.site-navbar-small .slidePanel-right,
.site-navbar-small .slidePanel-left {
  top: 60px;
}
@media (max-width: 767px) {
  body.site-navbar-small {
    padding-top: 60px;
  }
  body.site-navbar-small .site-menubar {
    top: 60px;
    height: -webkit-calc(100% - 60px);
    height: calc(100% - 60px);
  }
  body.site-navbar-small .page-aside {
    top: 60px;
    height: calc(100% -  60px);
  }
  body.site-navbar-small .page-aside-fixed .page-aside {
    top: 60px;
    height: calc(100% -  60px);
  }
  body.site-navbar-small .site-skintools {
    top: 90px;
  }
  body.site-navbar-small .slidePanel-right,
  body.site-navbar-small .slidePanel-left {
    top: 60px;
  }
  body.site-navbar-small.site-navbar-collapse-show {
    padding-top: 120px;
  }
  body.site-navbar-small.site-navbar-collapse-show .site-menubar {
    top: 120px;
    height: -webkit-calc(100% - 120px);
    height: calc(100% - 120px);
  }
  body.site-navbar-small.site-navbar-collapse-show .page-aside {
    top: 120px;
    height: calc(100% -  120px);
  }
  body.site-navbar-small.site-navbar-collapse-show .site-skintools {
    top: 150px;
  }
  body.site-navbar-small.site-navbar-collapse-show .slidePanel.slidePanel-left,
  body.site-navbar-small.site-navbar-collapse-show .slidePanel.slidePanel-right {
    top: 120px;
  }
}
@media (max-width: 767px) {
  .site-navbar .brand {
    display: none;
  }
  .site-navbar .brand-mobile {
    display: block;
  }
  .site-menubar {
    top: 66px;
    height: -webkit-calc(100% - 66px);
    height: calc(100% - 66px);
    backface-visibility: hidden;
    perspective: 1000;
    -ms-transform: translate(-260px, 0);
    -ms-transform: translate3d(-260px, 0, 0);
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
  }
  .site-navbar-collapse-show .site-menubar {
    top: 132px;
    height: -webkit-calc(100% - 132px);
    height: calc(100% - 132px);
  }
  .site-menubar-footer {
    visibility: hidden;
  }
  .site-menubar-open .site-menubar-footer {
    visibility: visible;
  }
  .disable-scrolling {
    overflow: hidden;
    height: 100%;
  }
  body.site-menubar-open {
    overflow: hidden;
    height: 100%;
  }
  body.site-menubar-open .page {
    overflow: hidden;
    height: 100%;
    pointer-events: none;
  }
}
.radio-custom,
.checkbox-custom {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio-custom label,
.checkbox-custom label {
  min-height: 22px;
  margin-bottom: 0;
  font-weight: 300;
  cursor: pointer;
}
.radio-custom input[type="radio"],
.checkbox-custom input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 0;
  margin-bottom: 0;
}
.radio-custom.disabled label,
.checkbox-custom.disabled label,
fieldset[disabled] .radio-custom label,
fieldset[disabled] .checkbox-custom label {
  cursor: not-allowed;
}
.input-group-addon .radio-custom,
.input-group-addon .checkbox-custom {
  margin: 0;
}
.checkbox-custom {
  padding-left: 20px;
}
.checkbox-custom label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
}
.checkbox-custom label:empty {
  padding-left: 0;
}
.checkbox-custom label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out 0s;
}
.checkbox-custom label::after {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  left: 0;
  top: 0;
  margin-left: -20px;
  text-align: center;
  padding-top: 1px;
  font-size: 12px;
  color: #757575;
}
.checkbox-custom input[type="checkbox"],
.checkbox-custom input[type="radio"] {
  opacity: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
}
.checkbox-custom input[type="checkbox"]:focus + label::before,
.checkbox-custom input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.checkbox-custom input[type="checkbox"]:checked + label::before,
.checkbox-custom input[type="radio"]:checked + label::before {
  border-color: #e0e0e0;
  border-width: 10px;
  transition: all 300ms ease-in-out 0s;
}
.checkbox-custom input[type="checkbox"]:checked + label::after,
.checkbox-custom input[type="radio"]:checked + label::after {
  font-family: "Material-Design-Iconic-Font";
  content: '\f26b';
}
.checkbox-custom input[type="checkbox"]:disabled + label,
.checkbox-custom input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.checkbox-custom input[type="checkbox"]:disabled + label::before,
.checkbox-custom input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  border-color: #e0e0e0;
  border-width: 1px;
  cursor: not-allowed;
}
.checkbox-custom.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox-custom.checkbox-inline {
  margin-top: 0;
  display: inline-block;
  margin-bottom: 0;
}
.checkbox-inline + .checkbox-inline {
  margin-left: 20px;
}
.checkbox-default input[type="checkbox"]:checked + label::before,
.checkbox-default input[type="radio"]:checked + label::before {
  background-color: #ffffff;
  border-color: #e0e0e0;
  border-width: 1px;
}
.checkbox-default input[type="checkbox"]:checked + label::after,
.checkbox-default input[type="radio"]:checked + label::after {
  color: #3f51b5;
}
.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #3f51b5;
  border-color: #3f51b5;
}
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #f44336;
  border-color: #f44336;
}
.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ff9800;
  border-color: #ff9800;
}
.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #4caf50;
  border-color: #4caf50;
}
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-sm {
  padding-left: 18px;
}
.checkbox-sm label {
  padding-left: 8px;
}
.checkbox-sm label:empty {
  padding-left: 0;
}
.checkbox-sm label::before,
.checkbox-sm label::after {
  width: 18px;
  height: 18px;
  margin-left: -18px;
}
.checkbox-sm label::after {
  font-size: 10px;
  line-height: 18px;
}
.checkbox-sm input[type="checkbox"],
.checkbox-sm input[type="radio"] {
  width: 18px;
  height: 18px;
}
.checkbox-sm input[type="checkbox"]:checked + label::before,
.checkbox-sm input[type="radio"]:checked + label::before {
  border-width: 9px;
}
.checkbox-lg {
  padding-left: 24px;
}
.checkbox-lg label {
  padding-left: 12px;
}
.checkbox-lg label:empty {
  padding-left: 0;
}
.checkbox-lg label::before,
.checkbox-lg label::after {
  width: 24px;
  height: 24px;
  margin-left: -24px;
}
.checkbox-lg label::after {
  font-size: 14px;
  line-height: 24px;
}
.checkbox-lg input[type="checkbox"],
.checkbox-lg input[type="radio"] {
  width: 24px;
  height: 24px;
}
.checkbox-lg input[type="checkbox"]:checked + label::before,
.checkbox-lg input[type="radio"]:checked + label::before {
  border-width: 12px;
}
.radio-custom {
  padding-left: 20px;
}
.radio-custom label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
}
.radio-custom label:empty {
  padding-left: 0;
}
.radio-custom label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  background-color: #ffffff;
  transition: border 300ms ease-in-out 0s, color 300ms ease-in-out 0s;
}
.radio-custom label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 6px;
  height: 6px;
  left: 7px;
  top: 7px;
  margin-left: -20px;
  border: 2px solid #757575;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  transition-transform: 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio-custom input[type="radio"] {
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 1;
}
.radio-custom input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.radio-custom input[type="radio"]:checked + label::before {
  border-color: #e0e0e0;
  border-width: 10px;
}
.radio-custom input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio-custom input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio-custom input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio-custom.radio-inline {
  margin-top: 0;
  display: inline-block;
  margin-bottom: 0;
}
.radio-inline + .radio-inline {
  margin-left: 20px;
}
.radio-default input[type="radio"]:checked + label::before {
  background-color: #ffffff;
  border-color: #e0e0e0;
  border-width: 1px;
}
.radio-default input[type="radio"]:checked + label::after {
  border-color: #3f51b5;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #3f51b5;
}
.radio-primary input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #f44336;
}
.radio-danger input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #00bcd4;
}
.radio-info input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ff9800;
}
.radio-warning input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #4caf50;
}
.radio-success input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-sm {
  padding-left: 18px;
}
.radio-sm label {
  padding-left: 8px;
}
.radio-sm label:empty {
  padding-left: 0;
}
.radio-sm label::before {
  width: 18px;
  height: 18px;
  margin-left: -20px;
}
.radio-sm label::after {
  width: 4px;
  height: 4px;
  left: 7px;
  top: 7px;
  margin-left: -20px;
  border-width: 2px;
}
.radio-sm input[type="radio"] {
  width: 18px;
  height: 18px;
}
.radio-sm input[type="radio"]:checked + label::before {
  border-width: 9px;
}
.radio-lg {
  padding-left: 24px;
}
.radio-lg label {
  padding-left: 12px;
}
.radio-lg label:empty {
  padding-left: 0;
}
.radio-lg label::before {
  width: 24px;
  height: 24px;
  margin-left: -20px;
}
.radio-lg label::after {
  width: 8px;
  height: 8px;
  left: 8px;
  top: 8px;
  margin-left: -20px;
  border-width: 2px;
}
.radio-lg input[type="radio"] {
  width: 24px;
  height: 24px;
}
.radio-lg input[type="radio"]:checked + label::before {
  border-width: 12px;
}
@media (min-width: 768px) {
  .form-inline .radio-custom,
  .form-inline .checkbox-custom {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio-custom label,
  .form-inline .checkbox-custom label {
    padding-left: 0;
  }
  .form-inline .radio-custom input[type="radio"],
  .form-inline .checkbox-custom input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .radio-custom label {
    padding-left: 10px;
  }
  .form-inline .checkbox-custom label {
    padding-left: 10px;
  }
  .form-inline .checkbox-custom input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
  }
  .form-inline .radio-custom input[type="radio"] {
    position: absolute;
    margin-left: -20px;
  }
}
.form-horizontal .radio-custom,
.form-horizontal .checkbox-custom {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}
.form-horizontal .radio-custom,
.form-horizontal .checkbox-custom {
  min-height: 29px;
}
.form-material {
  position: relative;
}
.form-material.floating {
  margin-top: 20px;
  margin-bottom: 20px;
}
.form-material.floating + .form-material.floating {
  margin-top: 40px;
}
.form-material .form-control {
  padding: 0;
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
}
.form-material .form-control,
.form-material .form-control:focus,
.form-material .form-control.focus {
  float: none;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background-image: linear-gradient(#3f51b5, #3f51b5), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material .form-control {
  border-bottom: 2px solid #e0e0e0;
}
.form-material .form-control::-webkit-input-placeholder {
  color: #9e9e9e;
}
.form-material .form-control::-moz-placeholder {
  color: #9e9e9e;
}
.form-material .form-control:-ms-input-placeholder {
  color: #9e9e9e;
}
.form-material .form-control:disabled::-webkit-input-placeholder {
  color: #bdbdbd;
}
.form-material .form-control:disabled::-moz-placeholder {
  color: #bdbdbd;
}
.form-material .form-control:disabled:-ms-input-placeholder {
  color: #bdbdbd;
}
.form-material .form-control:focus,
.form-material .form-control.focus {
  outline: none;
  background-size: 100% 2px, 100% 1px;
  transition-duration: 0.3s;
}
.no-cssgradients .form-material .form-control:focus,
.no-cssgradients .form-material .form-control.focus {
  background: transparent;
  border-bottom: 2px solid #3f51b5;
}
.form-material .form-control:disabled,
.form-material .form-control[disabled],
fieldset[disabled] .form-material .form-control {
  background: transparent;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px dashed #bdbdbd;
}
.form-material .form-control:disabled ~ .floating-label,
.form-material .form-control[disabled] ~ .floating-label,
fieldset[disabled] .form-material .form-control ~ .floating-label {
  color: #bdbdbd;
}
.form-material .control-label {
  font-weight: 500;
  margin-bottom: 0;
}
.form-material .floating-label {
  font-size: 14px;
  color: #757575;
  position: absolute;
  pointer-events: none;
  left: 0px;
  transition: 0.3s ease all;
}
.form-material .floating-label.floating-label-static {
  position: relative;
  top: auto;
  display: block;
}
.form-material [class*="col-"] > .floating-label {
  left: 15px;
}
.form-material .form-control ~ .floating-label {
  font-size: 14px;
  top: 7.99999998px;
}
.form-material .form-control:focus ~ .floating-label,
.form-material .form-control.focus ~ .floating-label,
.form-material .form-control:not(.empty) ~ .floating-label {
  top: -11.2px;
  font-size: 11.2px;
}
.form-material .form-control:-webkit-autofill ~ .floating-label {
  top: -11.2px;
  font-size: 11.2px;
}
.form-material .form-control.input-sm ~ .floating-label {
  font-size: 12px;
  top: 6px;
}
.form-material .form-control.input-sm:focus ~ .floating-label,
.form-material .form-control.input-sm.focus ~ .floating-label,
.form-material .form-control.input-sm:not(.empty) ~ .floating-label {
  top: -9.6px;
  font-size: 9.6px;
}
.form-material .form-control.input-sm:-webkit-autofill ~ .floating-label {
  top: -9.6px;
  font-size: 9.6px;
}
.form-material .form-control.input-lg ~ .floating-label {
  font-size: 18px;
  top: 5.9999994px;
}
.form-material .form-control.input-lg:focus ~ .floating-label,
.form-material .form-control.input-lg.focus ~ .floating-label,
.form-material .form-control.input-lg:not(.empty) ~ .floating-label {
  top: -14.4px;
  font-size: 14.4px;
}
.form-material .form-control.input-lg:-webkit-autofill ~ .floating-label {
  top: -14.4px;
  font-size: 14.4px;
}
.form-material .form-control:focus ~ .floating-label,
.form-material .form-control.focus ~ .floating-label,
.form-material .form-control:not(.empty) ~ .floating-label {
  font-weight: 500;
}
.form-material .form-control:-webkit-autofill ~ .floating-label {
  font-weight: 500;
}
.form-material .form-control:focus ~ .floating-label,
.form-material .form-control.focus ~ .floating-label {
  color: #3f51b5;
}
.form-material textarea.form-control {
  resize: none;
  padding-bottom: 6px;
}
.form-material.floating textarea.form-control {
  padding-top: 6px;
}
.form-material select.form-control {
  border: 0;
  border-radius: 0;
}
.form-material:not(.floating) .control-label + select[multiple] {
  margin-top: 5px;
}
.form-material .hint {
  position: absolute;
  font-size: 80%;
  display: none;
}
.form-material .form-control:focus ~ .hint,
.form-material .form-control.focus ~ .hint {
  display: block;
}
.form-material .form-control:not(.empty):invalid ~ .floating-label,
.form-material .form-control.focus:invalid ~ .floating-label {
  color: #f44336;
}
.form-material .form-control:invalid {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#e0e0e0, #e0e0e0);
}
.form-material.form-group.has-warning .form-control:focus,
.form-material.form-group.has-warning .form-control.focus,
.form-material.form-group.has-warning .form-control:not(.empty) {
  background-image: linear-gradient(#ff9800, #ff9800), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material.form-group.has-warning .form-control:focus,
.no-cssgradients .form-material.form-group.has-warning .form-control.focus,
.no-cssgradients .form-material.form-group.has-warning .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #ff9800;
}
.form-material.form-group.has-warning .form-control:-webkit-autofill {
  background-image: linear-gradient(#ff9800, #ff9800), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material.form-group.has-warning .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #ff9800;
}
.form-material.form-group.has-warning .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}
.form-material.form-group.has-warning .control-label {
  color: #ff9800;
}
.form-material.form-group.has-warning .form-control:focus ~ .floating-label,
.form-material.form-group.has-warning .form-control.focus ~ .floating-label,
.form-material.form-group.has-warning .form-control:not(.empty) ~ .floating-label {
  color: #ff9800;
}
.form-material.form-group.has-warning .form-control:-webkit-autofill ~ .floating-label {
  color: #ff9800;
}
.form-material.form-group.has-error .form-control:focus,
.form-material.form-group.has-error .form-control.focus,
.form-material.form-group.has-error .form-control:not(.empty) {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material.form-group.has-error .form-control:focus,
.no-cssgradients .form-material.form-group.has-error .form-control.focus,
.no-cssgradients .form-material.form-group.has-error .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #f44336;
}
.form-material.form-group.has-error .form-control:-webkit-autofill {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material.form-group.has-error .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #f44336;
}
.form-material.form-group.has-error .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}
.form-material.form-group.has-error .control-label {
  color: #f44336;
}
.form-material.form-group.has-error .form-control:focus ~ .floating-label,
.form-material.form-group.has-error .form-control.focus ~ .floating-label,
.form-material.form-group.has-error .form-control:not(.empty) ~ .floating-label {
  color: #f44336;
}
.form-material.form-group.has-error .form-control:-webkit-autofill ~ .floating-label {
  color: #f44336;
}
.form-material.form-group.has-success .form-control:focus,
.form-material.form-group.has-success .form-control.focus,
.form-material.form-group.has-success .form-control:not(.empty) {
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material.form-group.has-success .form-control:focus,
.no-cssgradients .form-material.form-group.has-success .form-control.focus,
.no-cssgradients .form-material.form-group.has-success .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #4caf50;
}
.form-material.form-group.has-success .form-control:-webkit-autofill {
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material.form-group.has-success .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #4caf50;
}
.form-material.form-group.has-success .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}
.form-material.form-group.has-success .control-label {
  color: #4caf50;
}
.form-material.form-group.has-success .form-control:focus ~ .floating-label,
.form-material.form-group.has-success .form-control.focus ~ .floating-label,
.form-material.form-group.has-success .form-control:not(.empty) ~ .floating-label {
  color: #4caf50;
}
.form-material.form-group.has-success .form-control:-webkit-autofill ~ .floating-label {
  color: #4caf50;
}
.form-material.form-group.has-info .form-control:focus,
.form-material.form-group.has-info .form-control.focus,
.form-material.form-group.has-info .form-control:not(.empty) {
  background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material.form-group.has-info .form-control:focus,
.no-cssgradients .form-material.form-group.has-info .form-control.focus,
.no-cssgradients .form-material.form-group.has-info .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #00bcd4;
}
.form-material.form-group.has-info .form-control:-webkit-autofill {
  background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#e0e0e0, #e0e0e0);
}
.no-cssgradients .form-material.form-group.has-info .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #00bcd4;
}
.form-material.form-group.has-info .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}
.form-material.form-group.has-info .control-label {
  color: #00bcd4;
}
.form-material.form-group.has-info .form-control:focus ~ .floating-label,
.form-material.form-group.has-info .form-control.focus ~ .floating-label,
.form-material.form-group.has-info .form-control:not(.empty) ~ .floating-label {
  color: #00bcd4;
}
.form-material.form-group.has-info .form-control:-webkit-autofill ~ .floating-label {
  color: #00bcd4;
}
.form-material .input-group .form-control-wrap {
  margin-right: 5px;
  margin-left: 5px;
}
.form-material .input-group .form-control-wrap .form-control {
  float: none;
}
.form-material .input-group .input-group-addon {
  border: 0;
  background: transparent;
}
.form-material .input-group .input-group-btn .btn {
  border-radius: 4px;
  margin: 0;
}
.form-material input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.form-control-wrap {
  position: relative;
}
.loader-wrapper {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  font-size: 0;
}
.loader-wrapper.loader-sm {
  width: 36px;
  height: 36px;
}
.loader-wrapper.loader-lg {
  width: 64px;
  height: 64px;
}
.loader-wrapper.active {
  animation: container-rotate 1568ms linear infinite;
}
@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}
.loader-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.active .loader-layer.loader-blue {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .loader-layer.loader-red {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .loader-layer.loader-yellow {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .loader-layer.loader-green {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .loader-layer.loader-blue-only,
.active .loader-layer.loader-red-only,
.active .loader-layer.loader-yellow-only,
.active .loader-layer.loader-green-only {
  opacity: 1;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.loader-blue,
.loader-blue-only {
  border-color: #1e88e5;
}
.loader-red,
.loader-red-only {
  border-color: #e53935;
}
.loader-yellow,
.loader-yellow-only {
  border-color: #fdd835;
}
.loader-green,
.loader-green-only {
  border-color: #43a047;
}
@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
  }
}
@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
@-webkit-keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@-webkit-keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@-webkit-keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loader-circle-gap {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.loader-circle-left,
.loader-circle-right {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.loader-circle-left .circle,
.loader-circle-right .circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 200%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
}
.loader-circle-left .circle {
  left: 0;
  border-right-color: transparent !important;
  -webkit-transform: rotate(129deg);
  -ms-transform: rotate(129deg);
  -o-transform: rotate(129deg);
  transform: rotate(129deg);
}
.active .loader-circle-left .circle {
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.loader-circle-right .circle {
  left: -100%;
  border-left-color: transparent !important;
  -webkit-transform: rotate(-129deg);
  -ms-transform: rotate(-129deg);
  -o-transform: rotate(-129deg);
  transform: rotate(-129deg);
}
.active .loader-circle-right .circle {
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
  }
}
@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}
@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
  }
}
@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}
[class*=animation-] {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animation-hover:not(:hover),
.animation-hover:not(:hover) [class*=animation-],
.touch .animation-hover:not(.hover),
.touch .animation-hover:not(.hover) [class*=animation-] {
  -webkit-animation-name: none;
  animation-name: none;
}
.animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}
.animation-repeat {
  animation-iteration-count: infinite;
}
.animation-fade {
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.animation-scale {
  -webkit-animation-name: scale-12;
  animation-name: scale-12;
}
.animation-scale-up {
  -webkit-animation-name: fade-scale-02;
  animation-name: fade-scale-02;
}
.animation-scale-down {
  -webkit-animation-name: fade-scale-18;
  animation-name: fade-scale-18;
}
.animation-slide-top {
  -webkit-animation-name: slide-top;
  animation-name: slide-top;
}
.animation-slide-bottom {
  -webkit-animation-name: slide-bottom;
  animation-name: slide-bottom;
}
.animation-slide-left {
  -webkit-animation-name: slide-left;
  animation-name: slide-left;
}
.animation-slide-right {
  -webkit-animation-name: slide-right;
  animation-name: slide-right;
}
.animation-shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
.animation-duration-10 {
  animation-duration: 15s;
}
.animation-duration-9 {
  animation-duration: 9s;
}
.animation-duration-8 {
  animation-duration: 8s;
}
.animation-duration-7 {
  animation-duration: 7s;
}
.animation-duration-6 {
  animation-duration: 6s;
}
.animation-duration-5 {
  animation-duration: 5s;
}
.animation-duration-4 {
  animation-duration: 4s;
}
.animation-duration-3 {
  animation-duration: 3s;
}
.animation-duration-2 {
  animation-duration: 2s;
}
.animation-duration-1 {
  animation-duration: 1s;
}
.animation-delay-100 {
  animation-duration: 100ms;
}
.animation-duration-250 {
  animation-duration: 250ms;
}
.animation-duration-300 {
  animation-duration: 300ms;
}
.animation-duration-500 {
  animation-duration: 500ms;
}
.animation-duration-750 {
  animation-duration: 750ms;
}
.animation-delay-1000 {
  animation-delay: 1s;
}
.animation-delay-900 {
  animation-delay: 900ms;
}
.animation-delay-800 {
  animation-delay: 800ms;
}
.animation-delay-700 {
  animation-delay: 700ms;
}
.animation-delay-600 {
  animation-delay: 600ms;
}
.animation-delay-500 {
  animation-delay: 500ms;
}
.animation-delay-400 {
  animation-delay: 400ms;
}
.animation-delay-300 {
  animation-delay: 300ms;
}
.animation-delay-200 {
  animation-delay: 200ms;
}
.animation-delay-100 {
  animation-delay: 100ms;
}
.animation-top-left {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.animation-top-center {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.animation-top-right {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.animation-middle-left {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.animation-middle-right {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.animation-bottom-left {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.animation-bottom-center {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.animation-bottom-right {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.animation-easing-easeInOut {
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
.animation-easing-easeInQuad {
  animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.animation-easing-easeInCubic {
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.animation-easing-easeInQuart {
  animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.animation-easing-easeInQuint {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.animation-easing-easeInSine {
  animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
.animation-easing-easeInExpo {
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}
.animation-easing-easeInCirc {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.animation-easing-easeInBack {
  animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.animation-easing-eastOutQuad {
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.animation-easing-easeOutCubic {
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.animation-easing-easeOutQuart {
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.animation-easing-easeOutQuint {
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.animation-easing-easeOutSine {
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.animation-easing-easeOutExpo {
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.animation-easing-easeOutCirc {
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.animation-easing-easeOutBack {
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.animation-easing-easeInOutQuad {
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.animation-easing-easeInOutCubic {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.animation-easing-easeInOutQuart {
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.animation-easing-easeInOutQuint {
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.animation-easing-easeInOutSine {
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.animation-easing-easeInOutExpo {
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
}
.animation-easing-easeInOutCirc {
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.animation-easing-easeInOutBack {
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.animation-easing-easeInOutElastic {
  animation-timing-function: cubic-bezier(1, -0.56, 0, 1.455);
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scale-12 {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-12 {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes fade-scale-18 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px);
  }
  20% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  30% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  40% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  60% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  70% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
.red-50 {
  color: #ffebee !important;
}
.red-100 {
  color: #ffcdd2 !important;
}
.red-200 {
  color: #ef9a9a !important;
}
.red-300 {
  color: #e57373 !important;
}
.red-400 {
  color: #ef5350 !important;
}
.red-500 {
  color: #f44336 !important;
}
.red-600 {
  color: #e53935 !important;
}
.red-700 {
  color: #d32f2f !important;
}
.red-800 {
  color: #c62828 !important;
}
.red-900 {
  color: #b71c1c !important;
}
.red-a100 {
  color: #ff8a80 !important;
}
.red-a200 {
  color: #ff5252 !important;
}
.red-a300 {
  color: #ff1744 !important;
}
.red-a400 {
  color: #d50000 !important;
}
.pink-50 {
  color: #fce4ec !important;
}
.pink-100 {
  color: #f8bbd0 !important;
}
.pink-200 {
  color: #f48fb1 !important;
}
.pink-300 {
  color: #f06292 !important;
}
.pink-400 {
  color: #ec407a !important;
}
.pink-500 {
  color: #e91e63 !important;
}
.pink-600 {
  color: #d81b60 !important;
}
.pink-700 {
  color: #c2185b !important;
}
.pink-800 {
  color: #ad1457 !important;
}
.pink-900 {
  color: #880e4f !important;
}
.pink-a100 {
  color: #ff80ab !important;
}
.pink-a200 {
  color: #ff4081 !important;
}
.pink-a300 {
  color: #f50057 !important;
}
.pink-a400 {
  color: #c51162 !important;
}
.purple-50 {
  color: #f3e5f5 !important;
}
.purple-100 {
  color: #e1bee7 !important;
}
.purple-200 {
  color: #ce93d8 !important;
}
.purple-300 {
  color: #ba68c8 !important;
}
.purple-400 {
  color: #ab47bc !important;
}
.purple-500 {
  color: #9c27b0 !important;
}
.purple-600 {
  color: #8e24aa !important;
}
.purple-700 {
  color: #7b1fa2 !important;
}
.purple-800 {
  color: #6a1b9a !important;
}
.purple-900 {
  color: #4a148c !important;
}
.purple-a100 {
  color: #ea80fc !important;
}
.purple-a200 {
  color: #e040fb !important;
}
.purple-a300 {
  color: #d500f9 !important;
}
.purple-a400 {
  color: #aa00ff !important;
}
.deep-purple-50 {
  color: #ede7f6 !important;
}
.deep-purple-100 {
  color: #d1c4e9 !important;
}
.deep-purple-200 {
  color: #b39ddb !important;
}
.deep-purple-300 {
  color: #9575cd !important;
}
.deep-purple-400 {
  color: #7e57c2 !important;
}
.deep-purple-500 {
  color: #673ab7 !important;
}
.deep-purple-600 {
  color: #5e35b1 !important;
}
.deep-purple-700 {
  color: #512da8 !important;
}
.deep-purple-800 {
  color: #4527a0 !important;
}
.deep-purple-900 {
  color: #311b92 !important;
}
.deep-purple-a100 {
  color: #b388ff !important;
}
.deep-purple-a200 {
  color: #7c4dff !important;
}
.deep-purple-a300 {
  color: #651fff !important;
}
.deep-purple-a400 {
  color: #6200ea !important;
}
.indigo-50 {
  color: #e8eaf6 !important;
}
.indigo-100 {
  color: #c5cae9 !important;
}
.indigo-200 {
  color: #9fa8da !important;
}
.indigo-300 {
  color: #7986cb !important;
}
.indigo-400 {
  color: #5c6bc0 !important;
}
.indigo-500 {
  color: #3f51b5 !important;
}
.indigo-600 {
  color: #3949ab !important;
}
.indigo-700 {
  color: #303f9f !important;
}
.indigo-800 {
  color: #283593 !important;
}
.indigo-900 {
  color: #1a237e !important;
}
.indigo-a100 {
  color: #8c9eff !important;
}
.indigo-a200 {
  color: #536dfe !important;
}
.indigo-a300 {
  color: #3d5afe !important;
}
.indigo-a400 {
  color: #304ffe !important;
}
.blue-50 {
  color: #e3f2fd !important;
}
.blue-100 {
  color: #bbdefb !important;
}
.blue-200 {
  color: #90caf9 !important;
}
.blue-300 {
  color: #64b5f6 !important;
}
.blue-400 {
  color: #42a5f5 !important;
}
.blue-500 {
  color: #2196f3 !important;
}
.blue-600 {
  color: #1e88e5 !important;
}
.blue-700 {
  color: #1976d2 !important;
}
.blue-800 {
  color: #1565c0 !important;
}
.blue-900 {
  color: #0d47a1 !important;
}
.blue-a100 {
  color: #82b1ff !important;
}
.blue-a200 {
  color: #448aff !important;
}
.blue-a300 {
  color: #2979ff !important;
}
.blue-a400 {
  color: #2962ff !important;
}
.light-blue-50 {
  color: #e1f5fe !important;
}
.light-blue-100 {
  color: #b3e5fc !important;
}
.light-blue-200 {
  color: #81d4fa !important;
}
.light-blue-300 {
  color: #4fc3f7 !important;
}
.light-blue-400 {
  color: #29b6f6 !important;
}
.light-blue-500 {
  color: #03a9f4 !important;
}
.light-blue-600 {
  color: #039be5 !important;
}
.light-blue-700 {
  color: #0288d1 !important;
}
.light-blue-800 {
  color: #0277bd !important;
}
.light-blue-900 {
  color: #01579b !important;
}
.light-blue-a100 {
  color: #80d8ff !important;
}
.light-blue-a200 {
  color: #40c4ff !important;
}
.light-blue-a300 {
  color: #00b0ff !important;
}
.light-blue-a400 {
  color: #0091ea !important;
}
.cyan-50 {
  color: #e0f7fa !important;
}
.cyan-100 {
  color: #b2ebf2 !important;
}
.cyan-200 {
  color: #80deea !important;
}
.cyan-300 {
  color: #4dd0e1 !important;
}
.cyan-400 {
  color: #26c6da !important;
}
.cyan-500 {
  color: #00bcd4 !important;
}
.cyan-600 {
  color: #00acc1 !important;
}
.cyan-700 {
  color: #0097a7 !important;
}
.cyan-800 {
  color: #00838f !important;
}
.cyan-900 {
  color: #006064 !important;
}
.cyan-a100 {
  color: #84ffff !important;
}
.cyan-a200 {
  color: #18ffff !important;
}
.cyan-a300 {
  color: #00e5ff !important;
}
.cyan-a400 {
  color: #00b8d4 !important;
}
.teal-50 {
  color: #e0f2f1 !important;
}
.teal-100 {
  color: #b2dfdb !important;
}
.teal-200 {
  color: #80cbc4 !important;
}
.teal-300 {
  color: #4db6ac !important;
}
.teal-400 {
  color: #26a69a !important;
}
.teal-500 {
  color: #009688 !important;
}
.teal-600 {
  color: #00897b !important;
}
.teal-700 {
  color: #00796b !important;
}
.teal-800 {
  color: #00695c !important;
}
.teal-900 {
  color: #004d40 !important;
}
.teal-a100 {
  color: #a7ffeb !important;
}
.teal-a200 {
  color: #64ffda !important;
}
.teal-a300 {
  color: #1de9b6 !important;
}
.teal-a400 {
  color: #00bfa5 !important;
}
.green-50 {
  color: #e8f5e9 !important;
}
.green-100 {
  color: #c8e6c9 !important;
}
.green-200 {
  color: #a5d6a7 !important;
}
.green-300 {
  color: #81c784 !important;
}
.green-400 {
  color: #66bb6a !important;
}
.green-500 {
  color: #4caf50 !important;
}
.green-600 {
  color: #43a047 !important;
}
.green-700 {
  color: #388e3c !important;
}
.green-800 {
  color: #2e7d32 !important;
}
.green-900 {
  color: #1b5e20 !important;
}
.green-a100 {
  color: #b9f6ca !important;
}
.green-a200 {
  color: #69f0ae !important;
}
.green-a300 {
  color: #00e676 !important;
}
.green-a400 {
  color: #00c853 !important;
}
.light-green-50 {
  color: #f1f8e9 !important;
}
.light-green-100 {
  color: #dcedc8 !important;
}
.light-green-200 {
  color: #c5e1a5 !important;
}
.light-green-300 {
  color: #aed581 !important;
}
.light-green-400 {
  color: #9ccc65 !important;
}
.light-green-500 {
  color: #8bc34a !important;
}
.light-green-600 {
  color: #7cb342 !important;
}
.light-green-700 {
  color: #689f38 !important;
}
.light-green-800 {
  color: #558b2f !important;
}
.light-green-900 {
  color: #33691e !important;
}
.light-green-a100 {
  color: #ccff90 !important;
}
.light-green-a200 {
  color: #b2ff59 !important;
}
.light-green-a300 {
  color: #76ff03 !important;
}
.light-green-a400 {
  color: #64dd17 !important;
}
.lime-50 {
  color: #f9fbe7 !important;
}
.lime-100 {
  color: #f0f4c3 !important;
}
.lime-200 {
  color: #e6ee9c !important;
}
.lime-300 {
  color: #dce775 !important;
}
.lime-400 {
  color: #d4e157 !important;
}
.lime-500 {
  color: #cddc39 !important;
}
.lime-600 {
  color: #c0ca33 !important;
}
.lime-700 {
  color: #afb42b !important;
}
.lime-800 {
  color: #9e9d24 !important;
}
.lime-900 {
  color: #827717 !important;
}
.lime-a100 {
  color: #f4ff81 !important;
}
.lime-a200 {
  color: #eeff41 !important;
}
.lime-a300 {
  color: #c6ff00 !important;
}
.lime-a400 {
  color: #aeea00 !important;
}
.yellow-50 {
  color: #fffde7 !important;
}
.yellow-100 {
  color: #fff9c4 !important;
}
.yellow-200 {
  color: #fff59d !important;
}
.yellow-300 {
  color: #fff176 !important;
}
.yellow-400 {
  color: #ffee58 !important;
}
.yellow-500 {
  color: #ffeb3b !important;
}
.yellow-600 {
  color: #fdd835 !important;
}
.yellow-700 {
  color: #fbc02d !important;
}
.yellow-800 {
  color: #f9a825 !important;
}
.yellow-900 {
  color: #f57f17 !important;
}
.yellow-a100 {
  color: #ffff8d !important;
}
.yellow-a200 {
  color: #ffff00 !important;
}
.yellow-a300 {
  color: #ffea00 !important;
}
.yellow-a400 {
  color: #ffd600 !important;
}
.amber-50 {
  color: #fff8e1 !important;
}
.amber-100 {
  color: #ffecb3 !important;
}
.amber-200 {
  color: #ffe082 !important;
}
.amber-300 {
  color: #ffd54f !important;
}
.amber-400 {
  color: #ffca28 !important;
}
.amber-500 {
  color: #ffc107 !important;
}
.amber-600 {
  color: #ffb300 !important;
}
.amber-700 {
  color: #ffa000 !important;
}
.amber-800 {
  color: #ff8f00 !important;
}
.amber-900 {
  color: #ff6f00 !important;
}
.amber-a100 {
  color: #ffe57f !important;
}
.amber-a200 {
  color: #ffd740 !important;
}
.amber-a300 {
  color: #ffc400 !important;
}
.amber-a400 {
  color: #ffab00 !important;
}
.orange-50 {
  color: #fff3e0 !important;
}
.orange-100 {
  color: #ffe0b2 !important;
}
.orange-200 {
  color: #ffcc80 !important;
}
.orange-300 {
  color: #ffb74d !important;
}
.orange-400 {
  color: #ffa726 !important;
}
.orange-500 {
  color: #ff9800 !important;
}
.orange-600 {
  color: #fb8c00 !important;
}
.orange-700 {
  color: #f57c00 !important;
}
.orange-800 {
  color: #ef6c00 !important;
}
.orange-900 {
  color: #e65100 !important;
}
.orange-a100 {
  color: #ffd180 !important;
}
.orange-a200 {
  color: #ffab40 !important;
}
.orange-a300 {
  color: #ff9100 !important;
}
.orange-a400 {
  color: #ff6d00 !important;
}
.deep-orange-50 {
  color: #fbe9e7 !important;
}
.deep-orange-100 {
  color: #ffccbc !important;
}
.deep-orange-200 {
  color: #ffab91 !important;
}
.deep-orange-300 {
  color: #ff8a65 !important;
}
.deep-orange-400 {
  color: #ff7043 !important;
}
.deep-orange-500 {
  color: #ff5722 !important;
}
.deep-orange-600 {
  color: #f4511e !important;
}
.deep-orange-700 {
  color: #e64a19 !important;
}
.deep-orange-800 {
  color: #d84315 !important;
}
.deep-orange-900 {
  color: #bf360c !important;
}
.deep-orange-a100 {
  color: #ff9e80 !important;
}
.deep-orange-a200 {
  color: #ff6e40 !important;
}
.deep-orange-a300 {
  color: #ff3d00 !important;
}
.deep-orange-a400 {
  color: #dd2c00 !important;
}
.primary-50 {
  color: #e8eaf6 !important;
}
.primary-100 {
  color: #c5cae9 !important;
}
.primary-200 {
  color: #9fa8da !important;
}
.primary-300 {
  color: #7986cb !important;
}
.primary-400 {
  color: #5c6bc0 !important;
}
.primary-500 {
  color: #3f51b5 !important;
}
.primary-600 {
  color: #3949ab !important;
}
.primary-700 {
  color: #303f9f !important;
}
.primary-800 {
  color: #283593 !important;
}
.primary-900 {
  color: #1a237e !important;
}
.brown-50 {
  color: #efebe9 !important;
}
.brown-100 {
  color: #d7ccc8 !important;
}
.brown-200 {
  color: #bcaaa4 !important;
}
.brown-300 {
  color: #a1887f !important;
}
.brown-400 {
  color: #8d6e63 !important;
}
.brown-500 {
  color: #795548 !important;
}
.brown-600 {
  color: #6d4c41 !important;
}
.brown-700 {
  color: #5d4037 !important;
}
.brown-800 {
  color: #4e342e !important;
}
.brown-900 {
  color: #3e2723 !important;
}
.blue-grey-50 {
  color: #eceff1 !important;
}
.blue-grey-100 {
  color: #cfd8dc !important;
}
.blue-grey-200 {
  color: #b0bec5 !important;
}
.blue-grey-300 {
  color: #90a4ae !important;
}
.blue-grey-400 {
  color: #78909c !important;
}
.blue-grey-500 {
  color: #607d8b !important;
}
.blue-grey-600 {
  color: #546e7a !important;
}
.blue-grey-700 {
  color: #455a64 !important;
}
.blue-grey-800 {
  color: #37474f !important;
}
.blue-grey-900 {
  color: #263238 !important;
}
.grey-50 {
  color: #fafafa !important;
}
.grey-100 {
  color: #f5f5f5 !important;
}
.grey-200 {
  color: #eeeeee !important;
}
.grey-300 {
  color: #e0e0e0 !important;
}
.grey-400 {
  color: #bdbdbd !important;
}
.grey-500 {
  color: #9e9e9e !important;
}
.grey-600 {
  color: #757575 !important;
}
.grey-700 {
  color: #616161 !important;
}
.grey-800 {
  color: #424242 !important;
}
.grey-900 {
  color: #212121 !important;
}
.bg-red-50 {
  background-color: #ffebee !important;
}
.bg-red-100 {
  background-color: #ffcdd2 !important;
}
.bg-red-200 {
  background-color: #ef9a9a !important;
}
.bg-red-300 {
  background-color: #e57373 !important;
}
.bg-red-400 {
  background-color: #ef5350 !important;
}
.bg-red-500 {
  background-color: #f44336 !important;
}
.bg-red-600 {
  background-color: #e53935 !important;
}
.bg-red-700 {
  background-color: #d32f2f !important;
}
.bg-red-800 {
  background-color: #c62828 !important;
}
.bg-red-900 {
  background-color: #b71c1c !important;
}
.bg-red-a100 {
  background-color: #ff8a80 !important;
}
.bg-red-a200 {
  background-color: #ff5252 !important;
}
.bg-red-a300 {
  background-color: #ff1744 !important;
}
.bg-red-a400 {
  background-color: #d50000 !important;
}
.bg-pink-50 {
  background-color: #fce4ec !important;
}
.bg-pink-100 {
  background-color: #f8bbd0 !important;
}
.bg-pink-200 {
  background-color: #f48fb1 !important;
}
.bg-pink-300 {
  background-color: #f06292 !important;
}
.bg-pink-400 {
  background-color: #ec407a !important;
}
.bg-pink-500 {
  background-color: #e91e63 !important;
}
.bg-pink-600 {
  background-color: #d81b60 !important;
}
.bg-pink-700 {
  background-color: #c2185b !important;
}
.bg-pink-800 {
  background-color: #ad1457 !important;
}
.bg-pink-900 {
  background-color: #880e4f !important;
}
.bg-pink-a100 {
  background-color: #ff80ab !important;
}
.bg-pink-a200 {
  background-color: #ff4081 !important;
}
.bg-pink-a300 {
  background-color: #f50057 !important;
}
.bg-pink-a400 {
  background-color: #c51162 !important;
}
.bg-purple-50 {
  background-color: #f3e5f5 !important;
}
.bg-purple-100 {
  background-color: #e1bee7 !important;
}
.bg-purple-200 {
  background-color: #ce93d8 !important;
}
.bg-purple-300 {
  background-color: #ba68c8 !important;
}
.bg-purple-400 {
  background-color: #ab47bc !important;
}
.bg-purple-500 {
  background-color: #9c27b0 !important;
}
.bg-purple-600 {
  background-color: #8e24aa !important;
}
.bg-purple-700 {
  background-color: #7b1fa2 !important;
}
.bg-purple-800 {
  background-color: #6a1b9a !important;
}
.bg-purple-900 {
  background-color: #4a148c !important;
}
.bg-purple-a100 {
  background-color: #ea80fc !important;
}
.bg-purple-a200 {
  background-color: #e040fb !important;
}
.bg-purple-a300 {
  background-color: #d500f9 !important;
}
.bg-purple-a400 {
  background-color: #aa00ff !important;
}
.bg-deep-purple-50 {
  background-color: #ede7f6 !important;
}
.bg-deep-purple-100 {
  background-color: #d1c4e9 !important;
}
.bg-deep-purple-200 {
  background-color: #b39ddb !important;
}
.bg-deep-purple-300 {
  background-color: #9575cd !important;
}
.bg-deep-purple-400 {
  background-color: #7e57c2 !important;
}
.bg-deep-purple-500 {
  background-color: #673ab7 !important;
}
.bg-deep-purple-600 {
  background-color: #5e35b1 !important;
}
.bg-deep-purple-700 {
  background-color: #512da8 !important;
}
.bg-deep-purple-800 {
  background-color: #4527a0 !important;
}
.bg-deep-purple-900 {
  background-color: #311b92 !important;
}
.bg-deep-purple-a100 {
  background-color: #b388ff !important;
}
.bg-deep-purple-a200 {
  background-color: #7c4dff !important;
}
.bg-deep-purple-a300 {
  background-color: #651fff !important;
}
.bg-deep-purple-a400 {
  background-color: #6200ea !important;
}
.bg-indigo-50 {
  background-color: #e8eaf6 !important;
}
.bg-indigo-100 {
  background-color: #c5cae9 !important;
}
.bg-indigo-200 {
  background-color: #9fa8da !important;
}
.bg-indigo-300 {
  background-color: #7986cb !important;
}
.bg-indigo-400 {
  background-color: #5c6bc0 !important;
}
.bg-indigo-500 {
  background-color: #3f51b5 !important;
}
.bg-indigo-600 {
  background-color: #3949ab !important;
}
.bg-indigo-700 {
  background-color: #303f9f !important;
}
.bg-indigo-800 {
  background-color: #283593 !important;
}
.bg-indigo-900 {
  background-color: #1a237e !important;
}
.bg-indigo-a100 {
  background-color: #8c9eff !important;
}
.bg-indigo-a200 {
  background-color: #536dfe !important;
}
.bg-indigo-a300 {
  background-color: #3d5afe !important;
}
.bg-indigo-a400 {
  background-color: #304ffe !important;
}
.bg-blue-50 {
  background-color: #e3f2fd !important;
}
.bg-blue-100 {
  background-color: #bbdefb !important;
}
.bg-blue-200 {
  background-color: #90caf9 !important;
}
.bg-blue-300 {
  background-color: #64b5f6 !important;
}
.bg-blue-400 {
  background-color: #42a5f5 !important;
}
.bg-blue-500 {
  background-color: #2196f3 !important;
}
.bg-blue-600 {
  background-color: #1e88e5 !important;
}
.bg-blue-700 {
  background-color: #1976d2 !important;
}
.bg-blue-800 {
  background-color: #1565c0 !important;
}
.bg-blue-900 {
  background-color: #0d47a1 !important;
}
.bg-blue-a100 {
  background-color: #82b1ff !important;
}
.bg-blue-a200 {
  background-color: #448aff !important;
}
.bg-blue-a300 {
  background-color: #2979ff !important;
}
.bg-blue-a400 {
  background-color: #2962ff !important;
}
.bg-light-blue-50 {
  background-color: #e1f5fe !important;
}
.bg-light-blue-100 {
  background-color: #b3e5fc !important;
}
.bg-light-blue-200 {
  background-color: #81d4fa !important;
}
.bg-light-blue-300 {
  background-color: #4fc3f7 !important;
}
.bg-light-blue-400 {
  background-color: #29b6f6 !important;
}
.bg-light-blue-500 {
  background-color: #03a9f4 !important;
}
.bg-light-blue-600 {
  background-color: #039be5 !important;
}
.bg-light-blue-700 {
  background-color: #0288d1 !important;
}
.bg-light-blue-800 {
  background-color: #0277bd !important;
}
.bg-light-blue-900 {
  background-color: #01579b !important;
}
.bg-light-blue-a100 {
  background-color: #80d8ff !important;
}
.bg-light-blue-a200 {
  background-color: #40c4ff !important;
}
.bg-light-blue-a300 {
  background-color: #00b0ff !important;
}
.bg-light-blue-a400 {
  background-color: #0091ea !important;
}
.bg-cyan-50 {
  background-color: #e0f7fa !important;
}
.bg-cyan-100 {
  background-color: #b2ebf2 !important;
}
.bg-cyan-200 {
  background-color: #80deea !important;
}
.bg-cyan-300 {
  background-color: #4dd0e1 !important;
}
.bg-cyan-400 {
  background-color: #26c6da !important;
}
.bg-cyan-500 {
  background-color: #00bcd4 !important;
}
.bg-cyan-600 {
  background-color: #00acc1 !important;
}
.bg-cyan-700 {
  background-color: #0097a7 !important;
}
.bg-cyan-800 {
  background-color: #00838f !important;
}
.bg-cyan-900 {
  background-color: #006064 !important;
}
.bg-cyan-a100 {
  background-color: #84ffff !important;
}
.bg-cyan-a200 {
  background-color: #18ffff !important;
}
.bg-cyan-a300 {
  background-color: #00e5ff !important;
}
.bg-cyan-a400 {
  background-color: #00b8d4 !important;
}
.bg-teal-50 {
  background-color: #e0f2f1 !important;
}
.bg-teal-100 {
  background-color: #b2dfdb !important;
}
.bg-teal-200 {
  background-color: #80cbc4 !important;
}
.bg-teal-300 {
  background-color: #4db6ac !important;
}
.bg-teal-400 {
  background-color: #26a69a !important;
}
.bg-teal-500 {
  background-color: #009688 !important;
}
.bg-teal-600 {
  background-color: #00897b !important;
}
.bg-teal-700 {
  background-color: #00796b !important;
}
.bg-teal-800 {
  background-color: #00695c !important;
}
.bg-teal-900 {
  background-color: #004d40 !important;
}
.bg-teal-a100 {
  background-color: #a7ffeb !important;
}
.bg-teal-a200 {
  background-color: #64ffda !important;
}
.bg-teal-a300 {
  background-color: #1de9b6 !important;
}
.bg-teal-a400 {
  background-color: #00bfa5 !important;
}
.bg-green-50 {
  background-color: #e8f5e9 !important;
}
.bg-green-100 {
  background-color: #c8e6c9 !important;
}
.bg-green-200 {
  background-color: #a5d6a7 !important;
}
.bg-green-300 {
  background-color: #81c784 !important;
}
.bg-green-400 {
  background-color: #66bb6a !important;
}
.bg-green-500 {
  background-color: #4caf50 !important;
}
.bg-green-600 {
  background-color: #43a047 !important;
}
.bg-green-700 {
  background-color: #388e3c !important;
}
.bg-green-800 {
  background-color: #2e7d32 !important;
}
.bg-green-900 {
  background-color: #1b5e20 !important;
}
.bg-green-a100 {
  background-color: #b9f6ca !important;
}
.bg-green-a200 {
  background-color: #69f0ae !important;
}
.bg-green-a300 {
  background-color: #00e676 !important;
}
.bg-green-a400 {
  background-color: #00c853 !important;
}
.bg-light-green-50 {
  background-color: #f1f8e9 !important;
}
.bg-light-green-100 {
  background-color: #dcedc8 !important;
}
.bg-light-green-200 {
  background-color: #c5e1a5 !important;
}
.bg-light-green-300 {
  background-color: #aed581 !important;
}
.bg-light-green-400 {
  background-color: #9ccc65 !important;
}
.bg-light-green-500 {
  background-color: #8bc34a !important;
}
.bg-light-green-600 {
  background-color: #7cb342 !important;
}
.bg-light-green-700 {
  background-color: #689f38 !important;
}
.bg-light-green-800 {
  background-color: #558b2f !important;
}
.bg-light-green-900 {
  background-color: #33691e !important;
}
.bg-light-green-a100 {
  background-color: #ccff90 !important;
}
.bg-light-green-a200 {
  background-color: #b2ff59 !important;
}
.bg-light-green-a300 {
  background-color: #76ff03 !important;
}
.bg-light-green-a400 {
  background-color: #64dd17 !important;
}
.bg-lime-50 {
  background-color: #f9fbe7 !important;
}
.bg-lime-100 {
  background-color: #f0f4c3 !important;
}
.bg-lime-200 {
  background-color: #e6ee9c !important;
}
.bg-lime-300 {
  background-color: #dce775 !important;
}
.bg-lime-400 {
  background-color: #d4e157 !important;
}
.bg-lime-500 {
  background-color: #cddc39 !important;
}
.bg-lime-600 {
  background-color: #c0ca33 !important;
}
.bg-lime-700 {
  background-color: #afb42b !important;
}
.bg-lime-800 {
  background-color: #9e9d24 !important;
}
.bg-lime-900 {
  background-color: #827717 !important;
}
.bg-lime-a100 {
  background-color: #f4ff81 !important;
}
.bg-lime-a200 {
  background-color: #eeff41 !important;
}
.bg-lime-a300 {
  background-color: #c6ff00 !important;
}
.bg-lime-a400 {
  background-color: #aeea00 !important;
}
.bg-yellow-50 {
  background-color: #fffde7 !important;
}
.bg-yellow-100 {
  background-color: #fff9c4 !important;
}
.bg-yellow-200 {
  background-color: #fff59d !important;
}
.bg-yellow-300 {
  background-color: #fff176 !important;
}
.bg-yellow-400 {
  background-color: #ffee58 !important;
}
.bg-yellow-500 {
  background-color: #ffeb3b !important;
}
.bg-yellow-600 {
  background-color: #fdd835 !important;
}
.bg-yellow-700 {
  background-color: #fbc02d !important;
}
.bg-yellow-800 {
  background-color: #f9a825 !important;
}
.bg-yellow-900 {
  background-color: #f57f17 !important;
}
.bg-yellow-a100 {
  background-color: #ffff8d !important;
}
.bg-yellow-a200 {
  background-color: #ffff00 !important;
}
.bg-yellow-a300 {
  background-color: #ffea00 !important;
}
.bg-yellow-a400 {
  background-color: #ffd600 !important;
}
.bg-amber-50 {
  background-color: #fff8e1 !important;
}
.bg-amber-100 {
  background-color: #ffecb3 !important;
}
.bg-amber-200 {
  background-color: #ffe082 !important;
}
.bg-amber-300 {
  background-color: #ffd54f !important;
}
.bg-amber-400 {
  background-color: #ffca28 !important;
}
.bg-amber-500 {
  background-color: #ffc107 !important;
}
.bg-amber-600 {
  background-color: #ffb300 !important;
}
.bg-amber-700 {
  background-color: #ffa000 !important;
}
.bg-amber-800 {
  background-color: #ff8f00 !important;
}
.bg-amber-900 {
  background-color: #ff6f00 !important;
}
.bg-amber-a100 {
  background-color: #ffe57f !important;
}
.bg-amber-a200 {
  background-color: #ffd740 !important;
}
.bg-amber-a300 {
  background-color: #ffc400 !important;
}
.bg-amber-a400 {
  background-color: #ffab00 !important;
}
.bg-orange-50 {
  background-color: #fff3e0 !important;
}
.bg-orange-100 {
  background-color: #ffe0b2 !important;
}
.bg-orange-200 {
  background-color: #ffcc80 !important;
}
.bg-orange-300 {
  background-color: #ffb74d !important;
}
.bg-orange-400 {
  background-color: #ffa726 !important;
}
.bg-orange-500 {
  background-color: #ff9800 !important;
}
.bg-orange-600 {
  background-color: #fb8c00 !important;
}
.bg-orange-700 {
  background-color: #f57c00 !important;
}
.bg-orange-800 {
  background-color: #ef6c00 !important;
}
.bg-orange-900 {
  background-color: #e65100 !important;
}
.bg-orange-a100 {
  background-color: #ffd180 !important;
}
.bg-orange-a200 {
  background-color: #ffab40 !important;
}
.bg-orange-a300 {
  background-color: #ff9100 !important;
}
.bg-orange-a400 {
  background-color: #ff6d00 !important;
}
.bg-deep-orange-50 {
  background-color: #fbe9e7 !important;
}
.bg-deep-orange-100 {
  background-color: #ffccbc !important;
}
.bg-deep-orange-200 {
  background-color: #ffab91 !important;
}
.bg-deep-orange-300 {
  background-color: #ff8a65 !important;
}
.bg-deep-orange-400 {
  background-color: #ff7043 !important;
}
.bg-deep-orange-500 {
  background-color: #ff5722 !important;
}
.bg-deep-orange-600 {
  background-color: #f4511e !important;
}
.bg-deep-orange-700 {
  background-color: #e64a19 !important;
}
.bg-deep-orange-800 {
  background-color: #d84315 !important;
}
.bg-deep-orange-900 {
  background-color: #bf360c !important;
}
.bg-deep-orange-a100 {
  background-color: #ff9e80 !important;
}
.bg-deep-orange-a200 {
  background-color: #ff6e40 !important;
}
.bg-deep-orange-a300 {
  background-color: #ff3d00 !important;
}
.bg-deep-orange-a400 {
  background-color: #dd2c00 !important;
}
.bg-primary-50 {
  background-color: #e8eaf6 !important;
}
.bg-primary-100 {
  background-color: #c5cae9 !important;
}
.bg-primary-200 {
  background-color: #9fa8da !important;
}
.bg-primary-300 {
  background-color: #7986cb !important;
}
.bg-primary-400 {
  background-color: #5c6bc0 !important;
}
.bg-primary-500 {
  background-color: #3f51b5 !important;
}
.bg-primary-600 {
  background-color: #3949ab !important;
}
.bg-primary-700 {
  background-color: #303f9f !important;
}
.bg-primary-800 {
  background-color: #283593 !important;
}
.bg-primary-900 {
  background-color: #1a237e !important;
}
.bg-brown-50 {
  background-color: #efebe9 !important;
}
.bg-brown-100 {
  background-color: #d7ccc8 !important;
}
.bg-brown-200 {
  background-color: #bcaaa4 !important;
}
.bg-brown-300 {
  background-color: #a1887f !important;
}
.bg-brown-400 {
  background-color: #8d6e63 !important;
}
.bg-brown-500 {
  background-color: #795548 !important;
}
.bg-brown-600 {
  background-color: #6d4c41 !important;
}
.bg-brown-700 {
  background-color: #5d4037 !important;
}
.bg-brown-800 {
  background-color: #4e342e !important;
}
.bg-brown-900 {
  background-color: #3e2723 !important;
}
.bg-blue-grey-50 {
  background-color: #eceff1 !important;
}
.bg-blue-grey-100 {
  background-color: #cfd8dc !important;
}
.bg-blue-grey-200 {
  background-color: #b0bec5 !important;
}
.bg-blue-grey-300 {
  background-color: #90a4ae !important;
}
.bg-blue-grey-400 {
  background-color: #78909c !important;
}
.bg-blue-grey-500 {
  background-color: #607d8b !important;
}
.bg-blue-grey-600 {
  background-color: #546e7a !important;
}
.bg-blue-grey-700 {
  background-color: #455a64 !important;
}
.bg-blue-grey-800 {
  background-color: #37474f !important;
}
.bg-blue-grey-900 {
  background-color: #263238 !important;
}
.bg-grey-50 {
  background-color: #fafafa !important;
}
.bg-grey-100 {
  background-color: #f5f5f5 !important;
}
.bg-grey-200 {
  background-color: #eeeeee !important;
}
.bg-grey-300 {
  background-color: #e0e0e0 !important;
}
.bg-grey-400 {
  background-color: #bdbdbd !important;
}
.bg-grey-500 {
  background-color: #9e9e9e !important;
}
.bg-grey-600 {
  background-color: #757575 !important;
}
.bg-grey-700 {
  background-color: #616161 !important;
}
.bg-grey-800 {
  background-color: #424242 !important;
}
.bg-grey-900 {
  background-color: #212121 !important;
}
.black {
  color: #000000 !important;
}
.white {
  color: #ffffff !important;
}
.bg-white {
  color: #757575;
  background-color: #ffffff;
}
.bg-primary {
  color: #ffffff;
  background-color: #3f51b5;
}
.bg-primary:hover {
  background-color: #606fc7;
}
.bg-primary a,
a.bg-primary {
  color: #ffffff;
}
.bg-primary a:hover,
a.bg-primary:hover {
  color: #ffffff;
}
.bg-success {
  color: #ffffff;
  background-color: #4caf50;
}
.bg-success:hover {
  background-color: #6ec071;
}
.bg-success a,
a.bg-success {
  color: #ffffff;
}
.bg-success a:hover,
a.bg-success:hover {
  color: #ffffff;
}
.bg-info {
  color: #ffffff;
  background-color: #00bcd4;
}
.bg-info:hover {
  background-color: #08e3ff;
}
.bg-info a,
a.bg-info {
  color: #ffffff;
}
.bg-info a:hover,
a.bg-info:hover {
  color: #ffffff;
}
.bg-warning {
  color: #ffffff;
  background-color: #ff9800;
}
.bg-warning:hover {
  background-color: #ffad33;
}
.bg-warning a,
a.bg-warning {
  color: #ffffff;
}
.bg-warning a:hover,
a.bg-warning:hover {
  color: #ffffff;
}
.bg-danger {
  color: #ffffff;
  background-color: #f44336;
}
.bg-danger:hover {
  background-color: #f77066;
}
.bg-danger a,
a.bg-danger {
  color: #ffffff;
}
.bg-danger a:hover,
a.bg-danger:hover {
  color: #ffffff;
}
.bg-dark {
  color: #ffffff;
  background-color: #616161;
}
.bg-dark:hover {
  background-color: #7a7a7a;
}
.bg-dark a,
a.bg-dark {
  color: #ffffff;
}
.bg-dark a:hover,
a.bg-dark:hover {
  color: #ffffff;
}
.social-facebook {
  color: #ffffff;
  background-color: #3b5998 !important;
}
.social-facebook:hover,
.social-facebook:focus {
  color: #ffffff;
  background-color: #4c70ba !important;
}
.social-facebook:active,
.social-facebook.active {
  color: #ffffff;
  background-color: #2d4373 !important;
}
.bg-facebook {
  background-color: #3b5998;
}
.social-twitter {
  color: #ffffff;
  background-color: #55acee !important;
}
.social-twitter:hover,
.social-twitter:focus {
  color: #ffffff;
  background-color: #83c3f3 !important;
}
.social-twitter:active,
.social-twitter.active {
  color: #ffffff;
  background-color: #2795e9 !important;
}
.bg-twitter {
  background-color: #55acee;
}
.social-google-plus {
  color: #ffffff;
  background-color: #dd4b39 !important;
}
.social-google-plus:hover,
.social-google-plus:focus {
  color: #ffffff;
  background-color: #e47365 !important;
}
.social-google-plus:active,
.social-google-plus.active {
  color: #ffffff;
  background-color: #c23321 !important;
}
.bg-google-plus {
  background-color: #dd4b39;
}
.social-linkedin {
  color: #ffffff;
  background-color: #0976b4 !important;
}
.social-linkedin:hover,
.social-linkedin:focus {
  color: #ffffff;
  background-color: #0b96e5 !important;
}
.social-linkedin:active,
.social-linkedin.active {
  color: #ffffff;
  background-color: #075683 !important;
}
.bg-linkedin {
  background-color: #0976b4;
}
.social-flickr {
  color: #ffffff;
  background-color: #ff0084 !important;
}
.social-flickr:hover,
.social-flickr:focus {
  color: #ffffff;
  background-color: #ff339d !important;
}
.social-flickr:active,
.social-flickr.active {
  color: #ffffff;
  background-color: #cc006a !important;
}
.bg-flickr {
  background-color: #ff0084;
}
.social-tumblr {
  color: #ffffff;
  background-color: #35465c !important;
}
.social-tumblr:hover,
.social-tumblr:focus {
  color: #ffffff;
  background-color: #485f7c !important;
}
.social-tumblr:active,
.social-tumblr.active {
  color: #ffffff;
  background-color: #222d3c !important;
}
.bg-tumblr {
  background-color: #35465c;
}
.social-xing {
  color: #ffffff;
  background-color: #024b4d !important;
}
.social-xing:hover,
.social-xing:focus {
  color: #ffffff;
  background-color: #037b7f !important;
}
.social-xing:active,
.social-xing.active {
  color: #ffffff;
  background-color: #011b1b !important;
}
.bg-xing {
  background-color: #024b4d;
}
.social-github {
  color: #ffffff;
  background-color: #4183c4 !important;
}
.social-github:hover,
.social-github:focus {
  color: #ffffff;
  background-color: #689cd0 !important;
}
.social-github:active,
.social-github.active {
  color: #ffffff;
  background-color: #3269a0 !important;
}
.bg-github {
  background-color: #4183c4;
}
.social-html5 {
  color: #ffffff;
  background-color: #e44f26 !important;
}
.social-html5:hover,
.social-html5:focus {
  color: #ffffff;
  background-color: #ea7453 !important;
}
.social-html5:active,
.social-html5.active {
  color: #ffffff;
  background-color: #bf3c18 !important;
}
.bg-html5 {
  background-color: #e44f26;
}
.social-openid {
  color: #ffffff;
  background-color: #f67d28 !important;
}
.social-openid:hover,
.social-openid:focus {
  color: #ffffff;
  background-color: #f89b59 !important;
}
.social-openid:active,
.social-openid.active {
  color: #ffffff;
  background-color: #e26309 !important;
}
.bg-openid {
  background-color: #f67d28;
}
.social-stack-overflow {
  color: #ffffff;
  background-color: #f86c01 !important;
}
.social-stack-overflow:hover,
.social-stack-overflow:focus {
  color: #ffffff;
  background-color: #fe882e !important;
}
.social-stack-overflow:active,
.social-stack-overflow.active {
  color: #ffffff;
  background-color: #c55601 !important;
}
.bg-stack-overflow {
  background-color: #f86c01;
}
.social-css3 {
  color: #ffffff;
  background-color: #1572b6 !important;
}
.social-css3:hover,
.social-css3:focus {
  color: #ffffff;
  background-color: #1a8fe4 !important;
}
.social-css3:active,
.social-css3.active {
  color: #ffffff;
  background-color: #105588 !important;
}
.bg-css3 {
  background-color: #1572b6;
}
.social-youtube {
  color: #ffffff;
  background-color: #b31217 !important;
}
.social-youtube:hover,
.social-youtube:focus {
  color: #ffffff;
  background-color: #e1171d !important;
}
.social-youtube:active,
.social-youtube.active {
  color: #ffffff;
  background-color: #850d11 !important;
}
.bg-youtube {
  background-color: #b31217;
}
.social-dribbble {
  color: #ffffff;
  background-color: #c32361 !important;
}
.social-dribbble:hover,
.social-dribbble:focus {
  color: #ffffff;
  background-color: #dc3d7b !important;
}
.social-dribbble:active,
.social-dribbble.active {
  color: #ffffff;
  background-color: #981b4b !important;
}
.bg-dribbble {
  background-color: #c32361;
}
.social-instagram {
  color: #ffffff;
  background-color: #3f729b !important;
}
.social-instagram:hover,
.social-instagram:focus {
  color: #ffffff;
  background-color: #548cb9 !important;
}
.social-instagram:active,
.social-instagram.active {
  color: #ffffff;
  background-color: #305777 !important;
}
.bg-instagram {
  background-color: #3f729b;
}
.social-pinterest {
  color: #ffffff;
  background-color: #cc2127 !important;
}
.social-pinterest:hover,
.social-pinterest:focus {
  color: #ffffff;
  background-color: #e04046 !important;
}
.social-pinterest:active,
.social-pinterest.active {
  color: #ffffff;
  background-color: #a01a1f !important;
}
.bg-pinterest {
  background-color: #cc2127;
}
.social-vk {
  color: #ffffff;
  background-color: #3d5a7d !important;
}
.social-vk:hover,
.social-vk:focus {
  color: #ffffff;
  background-color: #4e739f !important;
}
.social-vk:active,
.social-vk.active {
  color: #ffffff;
  background-color: #2c415b !important;
}
.bg-vk {
  background-color: #3d5a7d;
}
.social-yahoo {
  color: #ffffff;
  background-color: #350178 !important;
}
.social-yahoo:hover,
.social-yahoo:focus {
  color: #ffffff;
  background-color: #4b01ab !important;
}
.social-yahoo:active,
.social-yahoo.active {
  color: #ffffff;
  background-color: #1f0145 !important;
}
.bg-yahoo {
  background-color: #350178;
}
.social-behance {
  color: #ffffff;
  background-color: #1769ff !important;
}
.social-behance:hover,
.social-behance:focus {
  color: #ffffff;
  background-color: #4a8aff !important;
}
.social-behance:active,
.social-behance.active {
  color: #ffffff;
  background-color: #0050e3 !important;
}
.bg-behance {
  background-color: #024b4d;
}
.social-dropbox {
  color: #ffffff;
  background-color: #007ee5 !important;
}
.social-dropbox:hover,
.social-dropbox:focus {
  color: #ffffff;
  background-color: #1998ff !important;
}
.social-dropbox:active,
.social-dropbox.active {
  color: #ffffff;
  background-color: #0062b2 !important;
}
.bg-dropbox {
  background-color: #007ee5;
}
.social-reddit {
  color: #ffffff;
  background-color: #ff4500 !important;
}
.social-reddit:hover,
.social-reddit:focus {
  color: #ffffff;
  background-color: #ff6a33 !important;
}
.social-reddit:active,
.social-reddit.active {
  color: #ffffff;
  background-color: #cc3700 !important;
}
.bg-reddit {
  background-color: #ff4500;
}
.social-spotify {
  color: #ffffff;
  background-color: #7ab800 !important;
}
.social-spotify:hover,
.social-spotify:focus {
  color: #ffffff;
  background-color: #9ceb00 !important;
}
.social-spotify:active,
.social-spotify.active {
  color: #ffffff;
  background-color: #588500 !important;
}
.bg-spotify {
  background-color: #7ab800;
}
.social-vine {
  color: #ffffff;
  background-color: #00b488 !important;
}
.social-vine:hover,
.social-vine:focus {
  color: #ffffff;
  background-color: #00e7af !important;
}
.social-vine:active,
.social-vine.active {
  color: #ffffff;
  background-color: #008161 !important;
}
.bg-vine {
  background-color: #00b488;
}
.social-foursquare {
  color: #ffffff;
  background-color: #0cbadf !important;
}
.social-foursquare:hover,
.social-foursquare:focus {
  color: #ffffff;
  background-color: #2ad0f4 !important;
}
.social-foursquare:active,
.social-foursquare.active {
  color: #ffffff;
  background-color: #0992af !important;
}
.bg-foursquare {
  background-color: #0cbadf;
}
.social-vimeo {
  color: #ffffff;
  background-color: #1ab7ea !important;
}
.social-vimeo:hover,
.social-vimeo:focus {
  color: #ffffff;
  background-color: #49c6ee !important;
}
.social-vimeo:active,
.social-vimeo.active {
  color: #ffffff;
  background-color: #1295bf !important;
}
.bg-vimeo {
  background-color: #1ab7ea;
}
.social-skype {
  color: #ffffff;
  background-color: #77bcfd !important;
}
.social-skype:hover,
.social-skype:focus {
  color: #ffffff;
  background-color: #a9d5fe !important;
}
.social-skype:active,
.social-skype.active {
  color: #ffffff;
  background-color: #45a3fc !important;
}
.bg-skype {
  background-color: #77bcfd;
}
.social-evernote {
  color: #ffffff;
  background-color: #46bf8c !important;
}
.social-evernote:hover,
.social-evernote:focus {
  color: #ffffff;
  background-color: #6ccca4 !important;
}
.social-evernote:active,
.social-evernote.active {
  color: #ffffff;
  background-color: #369c71 !important;
}
.bg-evernote {
  background-color: #46bf8c;
}
a:focus {
  outline: none;
}
.blocks,
[class*="blocks-"] {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.blocks:before,
[class*="blocks-"]:before,
.blocks:after,
[class*="blocks-"]:after {
  content: " ";
  display: table;
}
.blocks:after,
[class*="blocks-"]:after {
  clear: both;
}
.blocks > li,
[class*="blocks-"] > li {
  margin-bottom: 22px;
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.blocks.no-space,
[class*="blocks-"].no-space {
  margin: 0;
}
.blocks.no-space > li,
[class*="blocks-"].no-space > li {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.blocks-2 > li:nth-child(2n+3),
.blocks-3 > li:nth-child(3n+4),
.blocks-4 > li:nth-child(4n+5),
.blocks-5 > li:nth-child(5n+6),
.blocks-6 > li:nth-child(6n+7),
.block-first {
  clear: both;
}
.blocks-100 > li {
  width: 100%;
}
.blocks-2 > li {
  width: 50%;
}
.blocks-3 > li {
  width: 33.33333333%;
}
.blocks-4 > li {
  width: 25%;
}
.blocks-5 > li {
  width: 20%;
}
.blocks-6 > li {
  width: 16.66666667%;
}
@media (min-width: 480px) {
  .blocks-xs-100 > li {
    width: 100%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-2 > li {
    width: 50%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-4 > li {
    width: 25%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-5 > li {
    width: 20%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-6 > li {
    width: 16.66666667%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-100 > li {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-2 > li {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-4 > li {
    width: 25%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-5 > li {
    width: 20%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-6 > li {
    width: 16.66666667%;
  }
}
@media (min-width: 992px) {
  .blocks-md-100 > li {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .blocks-md-2 > li {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .blocks-md-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 992px) {
  .blocks-md-4 > li {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .blocks-md-5 > li {
    width: 20%;
  }
}
@media (min-width: 992px) {
  .blocks-md-6 > li {
    width: 16.66666667%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-100 > li {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-2 > li {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-4 > li {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-5 > li {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-6 > li {
    width: 16.66666667%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-100 > li {
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-2 > li {
    width: 50%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-4 > li {
    width: 25%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-5 > li {
    width: 20%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-6 > li {
    width: 16.66666667%;
  }
}
.avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  white-space: nowrap;
  border-radius: 1000px;
  vertical-align: bottom;
}
.avatar i {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  border-radius: 100%;
}
.avatar img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0 none;
  border-radius: 1000px;
}
.avatar-online i {
  background-color: #4caf50;
}
.avatar-off i {
  background-color: #616161;
}
.avatar-busy i {
  background-color: #ff9800;
}
.avatar-away i {
  background-color: #f44336;
}
.avatar-100 {
  width: 100px;
}
.avatar-100 i {
  height: 20px;
  width: 20px;
}
.avatar-lg {
  width: 50px;
}
.avatar-lg i {
  height: 12px;
  width: 12px;
}
.avatar-sm {
  width: 30px;
}
.avatar-sm i {
  height: 8px;
  width: 8px;
}
.avatar-xs {
  width: 20px;
}
.avatar-xs i {
  height: 7px;
  width: 7px;
}
.status {
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  border-radius: 100%;
}
.status-online {
  background-color: #4caf50;
}
.status-off {
  background-color: #616161;
}
.status-busy {
  background-color: #ff9800;
}
.status-away {
  background-color: #f44336;
}
.status-lg {
  width: 14px;
  height: 14px;
}
.status-md {
  width: 10px;
  height: 10px;
}
.status-sm {
  width: 8px;
  height: 8px;
}
.status-xs {
  width: 7px;
  height: 7px;
}
.icon {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  line-height: 1;
}
.icon.pull-left {
  margin-right: .3em;
}
.icon.pull-right {
  margin-left: .3em;
}
.icon.icon-circle {
  position: relative;
  margin: .5em;
}
.icon.icon-circle:before {
  position: relative;
  z-index: 1;
}
.icon.icon-circle:after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  content: "";
  width: 2em;
  height: 2em;
  background-color: inherit;
  border-radius: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.icon.icon-lg {
  font-size: 1.33333333em;
  vertical-align: -15%;
}
.icon.icon-2x {
  font-size: 2em;
}
.icon.icon-3x {
  font-size: 3em;
}
.icon.icon-4x {
  font-size: 4em;
}
.icon.icon-5x {
  font-size: 5em;
}
.icon.icon-fw {
  width: 1.28571429em;
  text-align: center;
}
.icon.icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.icon.icon-ul > li {
  position: relative;
}
.icon.icon-li {
  position: absolute;
  top: 0.14285714em;
  left: -2.14285714em;
  width: 2.14285714em;
  text-align: center;
}
.icon.icon-li.icon-lg {
  left: -1.85714286em;
}
.icon.icon-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #e0e0e0;
  border-radius: .1em;
}
.icon.icon-spin {
  animation: icon-spin 2s infinite linear;
}
.icon.icon-spin-reverse {
  animation: icon-spin-reverse 2s infinite linear;
}
.icon.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon.icon-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
.icon.icon-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.icon.icon-stack-1x,
.icon.icon-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.icon.icon-stack-1x {
  line-height: inherit;
}
.icon.icon-stack-2x {
  font-size: 2em;
}
.icon.icon-stack-inverse {
  color: #ffffff;
}
.icon-color {
  color: rgba(66, 66, 66, 0.4);
}
.icon-color:hover,
.icon-color:focus {
  color: rgba(66, 66, 66, 0.6);
}
.icon-color.active,
.icon-color:active {
  color: #424242;
}
.icon-color-alt {
  color: rgba(66, 66, 66, 0.6);
}
.icon-color-alt:hover,
.icon-color-alt:focus {
  color: rgba(66, 66, 66, 0.8);
}
.icon-color-alt.active,
.icon-color-alt:active {
  color: #424242;
}
:root-rotate-90,
:root-rotate-180,
:root-rotate-270,
:root-flip-horizontal,
:root-flip-vertical {
  filter: none;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes icon-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
@keyframes icon-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
.hamburger {
  vertical-align: middle;
  font-size: 17px;
}
.hamburger,
.hamburger:before,
.hamburger .hamburger-bar,
.hamburger:after {
  transition: transform 0.2s ease-in-out;
}
.hamburger:before,
.hamburger:after {
  content: "";
}
.hamburger:before,
.hamburger .hamburger-bar,
.hamburger:after {
  display: block;
  width: 1em;
  height: 0.1em;
  margin: 0;
  border-radius: 1px;
  background: #757575;
}
.navbar-default .hamburger:before,
.navbar-default .hamburger .hamburger-bar,
.navbar-default .hamburger:after {
  background: #757575;
}
.navbar-inverse .hamburger:before,
.navbar-inverse .hamburger .hamburger-bar,
.navbar-inverse .hamburger:after {
  background: #ffffff;
}
.hamburger .hamburger-bar {
  margin: 0.2em 0;
}
.hamburger-close:before {
  transform: rotate(45deg);
  transform-origin: 8%;
}
.hamburger-close .hamburger-bar {
  opacity: 0;
}
.hamburger-close:after {
  transform: rotate(-45deg);
  transform-origin: 8%;
}
.hamburger-close.hided:before,
.hamburger-close.collapsed:before {
  transform: rotate(0);
}
.hamburger-close.hided .hamburger-bar,
.hamburger-close.collapsed .hamburger-bar {
  opacity: 1;
}
.hamburger-close.hided:after,
.hamburger-close.collapsed:after {
  transform: rotate(0);
}
.hamburger-arrow-left.collapsed {
  transform: rotate(180deg);
}
.hamburger-arrow-left.collapsed:before {
  width: .6em;
  transform: translate3d(0.45em, 0.1em, 0) rotate(45deg);
}
.hamburger-arrow-left.collapsed .hamburger-bar {
  border-radius: 0.2em;
}
.hamburger-arrow-left.collapsed:after {
  width: .6em;
  transform: translate3d(0.45em, -0.1em, 0) rotate(-45deg);
}
.counter {
  text-align: center;
}
.counter > .counter-number,
.counter .counter-number-group {
  color: #424242;
  font-size: 20px;
}
.counter-label {
  display: block;
}
.counter-icon {
  font-size: 20px;
}
.counter-lg > .counter-number,
.counter-lg .counter-number-group {
  font-size: 40px;
}
.counter-lg .counter-icon {
  font-size: 40px;
}
.counter-md > .counter-number,
.counter-md .counter-number-group {
  font-size: 30px;
}
.counter-md .counter-icon {
  font-size: 30px;
}
.counter-sm > .counter-number,
.counter-sm .counter-number-group {
  font-size: 14px;
}
.counter-sm .counter-icon {
  font-size: 14px;
}
.counter-sm .counter-number-related + .counter-number,
.counter-sm .counter-number + .counter-number-related {
  margin-left: 0;
}
.counter-inverse {
  color: #ffffff;
}
.counter-inverse > .counter-number,
.counter-inverse .counter-number-group {
  color: #ffffff;
}
.counter-inverse .counter-icon {
  color: #ffffff;
}
.widget {
  position: relative;
  background-color: #ffffff;
  margin-bottom: 30px;
}
.widget .cover {
  width: 100%;
}
[class*="blocks-"] > li > .widget {
  margin-bottom: 0;
}
.widget-shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.widget {
  border-radius: 3px;
}
.widget .widget-header {
  border-radius: 3px 3px 0 0;
}
.widget .widget-header:last-child {
  border-radius: inherit;
}
.widget .widget-body:last-child {
  border-radius: 0 0 3px 3px;
}
.widget .widget-body:last-child .widget-body-footer {
  border-radius: 0 0 3px 3px;
}
.widget .widget-footer {
  border-radius: 0 0 3px 3px;
}
.widget .widget-footer:first-child {
  border-radius: inherit;
}
.widget-body {
  position: relative;
  padding: 30px 25px;
}
.widget-body-footer {
  margin-top: 30px;
}
.widget-body-footer:before,
.widget-body-footer:after {
  content: " ";
  display: table;
}
.widget-body-footer:after {
  clear: both;
}
.widget-content ul {
  margin: 0;
  padding: 0;
}
.widget-content li {
  list-style: none;
}
.widget-title {
  margin-top: 0;
  color: #424242;
  text-transform: capitalize;
}
div.widget-title {
  font-size: 22px;
}
.overlay-panel .widget-title {
  color: #ffffff;
}
.widget > .widget-title {
  padding: 12px 20px;
}
.widget-metas {
  color: #9e9e9e;
  font-size: 12px;
}
.widget-metas.type-link > a {
  position: relative;
  display: inline-block;
  padding: 3px 5px;
  color: #9e9e9e;
}
.widget-metas.type-link > a:first-child {
  padding-left: 0;
}
.widget-metas.type-link > a:hover {
  color: #bdbdbd;
}
.widget-metas.type-link > a + a:before {
  position: absolute;
  top: 10px;
  left: -2px;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #9e9e9e;
  border-radius: 50%;
}
.overlay-background .widget-time {
  opacity: 0.8;
  color: #ffffff;
}
.widget-category {
  font-size: 16px;
}
.widget-actions {
  margin-top: 10px;
  text-align: right;
}
.widget-actions a {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  color: #9e9e9e;
}
.widget-actions a.icon,
.widget-actions a .icon {
  text-decoration: none;
}
.widget-actions a.icon + span,
.widget-actions a .icon + span {
  margin-left: 2px;
}
.widget-actions a.active,
.widget-actions a:hover,
.widget-actions a:focus {
  text-decoration: none;
  color: #bdbdbd;
}
.widget-actions a:last-child {
  margin-right: 0;
}
.widget-actions-sidebar {
  width: 60px;
  position: absolute;
  top: 20px;
  left: 20px;
}
.widget-actions-sidebar a {
  display: inline-block;
  width: 100%;
  height: 60px;
  text-align: center;
  border-right: 1px solid #e0e0e0;
  margin-right: 0;
}
.widget-actions-sidebar a:before {
  display: inline-block;
  content: '';
  height: 100%;
  vertical-align: middle;
}
.widget-actions-sidebar a + a {
  border-top: 1px solid #e0e0e0;
}
.widget-actions-sidebar + .widget-content {
  margin-left: 80px;
}
.widget-watermark {
  position: absolute;
  opacity: 0.1;
  right: 0;
  bottom: 0;
  line-height: 1;
}
.widget-watermark.darker {
  color: black;
}
.widget-watermark.lighter {
  color: white;
}
.widget-divider:after {
  width: 20px;
  height: 2px;
  content: "";
  display: block;
  margin: 15px auto;
  background-color: #ffffff;
}
.widget-left {
  position: absolute;
  width: 40%;
  height: 100%;
  top: 0;
  left: 0;
}
.widget-left + .widget-body {
  margin-left: 40%;
  width: 60%;
}
@media (max-width: 767px) {
  .widget-left {
    position: relative;
    width: 100%;
    height: 320px;
  }
  .widget-left + .widget-body {
    margin-left: 0;
    width: 100%;
  }
}
.panel-group .panel {
  box-shadow: none;
}
.panel-group .panel-title {
  position: relative;
  padding: 15px 45px 15px 30px;
  font-size: 14px;
}
.panel-group .panel-title:before,
.panel-group .panel-title:after {
  position: absolute;
  top: 15px;
  right: 30px;
  font-family: "Material-Design-Iconic-Font";
  transition: all 300ms linear 0s;
}
.panel-group .panel-title:before {
  content: '\f278';
}
.panel-group .panel-title:after {
  content: '\f273';
}
.panel-group .panel-title[aria-expanded="false"]:before {
  opacity: .4;
}
.panel-group .panel-title[aria-expanded="false"]:after {
  opacity: 0;
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.panel-group .panel-title[aria-expanded="true"]:before {
  opacity: 0;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.panel-group .panel-title[aria-expanded="true"]:after {
  opacity: 1;
}
.panel-group .panel-title:hover,
.panel-group .panel-title:focus {
  color: #757575;
  text-decoration: none;
}
.panel-group .panel-title:focus {
  outline: none;
}
.panel-group .panel-heading + .panel-collapse {
  margin: 0;
}
.panel-group .panel-collapse .panel-body {
  padding: 15px 30px;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top-color: transparent;
}
.panel-group .panel + .panel {
  margin-top: 10px;
}
.panel-group-continuous .panel {
  border-radius: 0;
}
.panel-group-continuous .panel:first-child {
  border-radius: 4px 4px 0 0;
}
.panel-group-continuous .panel:last-child {
  border-radius: 0 0 4px 4px;
}
.panel-group-continuous .panel + .panel {
  margin-top: 0;
  border-top: 1px solid #e0e0e0;
}
.panel-group-simple .panel {
  background: transparent;
  box-shadow: none;
}
.panel-group-simple .panel-title {
  padding-left: 0;
  padding-bottom: 10px;
}
.panel-group-simple .panel-title:before,
.panel-group-simple .panel-title:after {
  right: 5px;
}
.panel-group-simple .panel-collapse .panel-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
}
.panel-group-simple .panel + .panel {
  margin-top: 0;
}
.cover {
  overflow: hidden;
}
.cover-background {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.cover-image {
  width: 100%;
}
.cover-quote {
  position: relative;
  margin-bottom: 0;
  padding-left: 35px;
  border-left: none;
}
.cover-quote:before,
.cover-quote:after {
  position: absolute;
  top: -20px;
  font-size: 4em;
}
.cover-quote:before {
  left: 0;
  content: open-quote;
}
.cover-quote:after {
  right: 0;
  content: close-quote;
  visibility: hidden;
}
.cover-quote.blockquote-reverse {
  padding-right: 35px;
  padding-left: 20px;
  border-right: none;
}
.cover-quote.blockquote-reverse:before {
  right: 0;
  left: auto;
  content: close-quote;
}
.cover-gallery .carousel-inner img {
  width: 100%;
}
.cover-iframe {
  width: 100%;
  border: 0 none;
}
/* ========================================================================
   Component: Overlay
 ========================================================================== */
.overlay {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.overlay > :first-child,
.overlay .overlay-figure {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
}
/* Sub-object `overlay-panel`
 ========================================================================== */
.overlay-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  color: #ffffff;
}
.overlay-panel a:not([class]) {
  color: inherit;
  text-decoration: underline;
}
.overlay-panel > :last-child {
  margin-bottom: 0;
}
.overlay-panel h1,
.overlay-panel h2,
.overlay-panel h3,
.overlay-panel h4,
.overlay-panel h5,
.overlay-panel h6 {
  color: inherit;
}
/* Sub-object `overlay-hover`
 ========================================================================== */
.overlay-hover:not(:hover) .overlay-panel:not(.overlay-background-fixed) {
  opacity: 0;
}
/* Modifier `overlay-background`
 ========================================================================== */
.overlay-background {
  background: rgba(0, 0, 0, 0.5);
}
/* Modifier `overlay-image`
 ========================================================================== */
.overlay-image {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
/* Modifier `overlay-shade`
 ========================================================================== */
.overlay-shade {
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.87) 90%, #ffffff 100%) repeat scroll 0 0;
}
/* Position modifiers
 ========================================================================== */
.overlay-top {
  bottom: auto;
}
.overlay-bottom {
  top: auto;
}
.overlay-left {
  right: auto;
}
.overlay-right {
  left: auto;
}
/* Sub-object `overlay-icon`
 ========================================================================== */
.overlay-icon {
  font-size: 0;
  text-align: center;
}
.overlay-icon:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.overlay-icon .icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 32px;
  line-height: 1;
  color: #ffffff;
  text-decoration: none;
}
/* Sub-object `overlay-anchor`
 ========================================================================== */
.overlay-anchor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* Transitions
 ========================================================================== */
.overlay-fade,
.overlay-scale,
.overlay-spin,
.overlay-grayscale,
.overlay-blur,
[class*='overlay-slide'] {
  transition-timing-function: ease-out;
  transition-duration: .3s;
  transition-property: opacity transform filter;
}
.overlay-fade {
  opacity: 0.7;
}
.overlay-hover:hover .overlay-fade {
  opacity: 1;
}
.overlay-scale {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.overlay-hover:hover .overlay-scale {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.overlay-spin {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.overlay-hover:hover .overlay-spin {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}
.overlay-grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.overlay-hover:hover .overlay-grayscale {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
[class*='overlay-slide'] {
  opacity: 0;
}
/* Top */
.overlay-slide-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
/* Bottom */
.overlay-slide-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
/* Left */
.overlay-slide-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
/* Right */
.overlay-slide-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
/* Hover */
.overlay-hover:hover [class*='overlay-slide'] {
  opacity: 1;
  -webkit-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}
.comments {
  padding: 0;
  margin: 0;
}
.comments .comment {
  border: none;
  border-bottom: 1px solid #e0e0e0;
}
.comments .comment .comment:first-child {
  border-top: 1px solid #e0e0e0;
}
.comments .comment .comment:last-child {
  border-bottom: none;
}
.comment {
  padding: 20px 0;
  margin: 0;
}
.comment .comment {
  margin-top: 20px;
  padding-bottom: 20px;
}
.comment .comment:last-child {
  padding-bottom: 0;
}
.comment-author,
.comment-author:hover,
.comment-author:focus {
  color: #424242;
}
.comment-meta {
  margin-left: 5px;
  display: inline-block;
  font-size: 12px;
  color: #9e9e9e;
}
.comment-content {
  margin-top: 5px;
}
.comment-content p {
  margin-bottom: 10px;
}
.comment-actions {
  margin-top: 10px;
  text-align: right;
}
.comment-actions a {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
.comment-actions a.icon {
  text-decoration: none;
}
.comment-actions a:last-child {
  margin-right: 0;
}
.comment-reply {
  margin: 22px 0 10px;
}
.comment-reply .form-group:last-child {
  margin-bottom: 0;
}
.chat-box {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
}
.chats {
  padding: 30px 15px;
}
.chat-body {
  display: block;
  margin: 0;
  overflow: hidden;
}
.chat-content {
  position: relative;
  display: block;
  float: left;
  padding: 15px 20px;
  margin: 0 0 30px 20px;
  clear: both;
  color: #ffffff;
  background-color: #7986cb;
  border-radius: 4px;
}
.chat-content:before {
  position: absolute;
  top: 0px;
  left: -10px;
  width: 0;
  height: 0;
  content: '';
  border: 10px solid transparent;
  border-top-color: #7986cb;
}
.chat-content > p:last-child {
  margin-bottom: 0;
}
.chat-right .chat-content {
  float: right;
  margin: 0 20px 30px 0;
  color: #757575;
  background-color: #dfdfdf;
}
.chat-right .chat-content:before {
  left: auto;
  right: -10px;
  border-top-color: #dfdfdf;
}
.steps {
  margin-bottom: 22px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.steps.row {
  margin-left: 0;
  margin-right: 0;
  display: block;
}
.step {
  position: relative;
  padding: 12px 20px;
  margin: 0;
  color: #9e9e9e;
  font-size: inherit;
  vertical-align: top;
  background-color: #eeeeee;
  border-radius: 0;
}
.step-icon {
  float: left;
  font-size: 27px;
  margin-right: 0.5em;
}
.step-number {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  background: #e0e0e0;
  border-radius: 50%;
}
.step-number ~ .step-desc {
  min-height: 40px;
  margin-left: 50px;
}
.step-title {
  font-size: 20px;
  color: #616161;
  margin-bottom: 0;
}
.step-desc {
  text-align: left;
}
.step-desc p {
  margin-bottom: 0;
}
.steps-vertical .step {
  display: block;
  padding: 18px 20px;
}
.steps-vertical .step[class*="col-"] {
  width: 100%;
  float: none;
}
.step.current {
  color: #ffffff;
  background-color: #3f51b5;
}
.step.current .step-title {
  color: #ffffff;
}
.step.current .step-number {
  color: #3f51b5;
  background-color: #ffffff;
}
.step.disabled {
  color: #bdbdbd;
  pointer-events: none;
  cursor: auto;
}
.step.disabled .step-title {
  color: #bdbdbd;
}
.step.disabled .step-number {
  background-color: #bdbdbd;
}
.step.error {
  color: #ffffff;
  background-color: #f44336;
}
.step.error .step-title {
  color: #ffffff;
}
.step.error .step-number {
  color: #f44336;
  background-color: #ffffff;
}
.step.done {
  color: #ffffff;
  background-color: #4caf50;
}
.step.done .step-title {
  color: #ffffff;
}
.step.done .step-number {
  color: #4caf50;
  background-color: #ffffff;
}
.steps-lg .step {
  padding: 20px 20px;
  font-size: 16px;
}
.steps-lg .step-icon {
  font-size: 32px;
}
.steps-lg .step-title {
  font-size: 22px;
}
.steps-lg .step-number {
  width: 46px;
  height: 46px;
  font-size: 28px;
  line-height: 46px;
}
.steps-lg .step-number ~ .step-desc {
  min-height: 46px;
  margin-left: 56px;
}
.steps-sm .step {
  font-size: 12px;
}
.steps-sm .step-icon {
  font-size: 24px;
}
.steps-sm .step-title {
  font-size: 18px;
}
.steps-sm .step-number {
  width: 30px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
}
.steps-sm .step-number ~ .step-desc {
  min-height: 30px;
  margin-left: 40px;
}
.steps-xs .step {
  font-size: 10px;
}
.steps-xs .step-icon {
  font-size: 22px;
}
.steps-xs .step-title {
  font-size: 16px;
}
.steps-xs .step-number {
  width: 24px;
  height: 24px;
  font-size: 20px;
  line-height: 24px;
}
.steps-xs .step-number ~ .step-desc {
  min-height: 24px;
  margin-left: 34px;
}
.pearls {
  margin-bottom: 22px;
}
.pearls.row {
  display: block;
}
.pearl {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}
.pearl:before,
.pearl:after {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #eeeeee;
}
.pearl:before {
  left: 0;
}
.pearl:after {
  right: 0;
}
.pearl:first-child:before,
.pearl:last-child:after {
  display: none !important;
}
.pearl-number,
.pearl-icon {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  background: #bdbdbd;
  border-radius: 50%;
  border: 2px solid #bdbdbd;
}
.pearl-number {
  font-size: 18px;
}
.pearl-icon {
  font-size: 18px;
}
.pearl-title {
  margin-top: 0.5em;
  display: block;
  font-size: 16px;
  color: #616161;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  /* for IE */
  white-space: nowrap;
}
.pearl.current:before,
.pearl.current:after {
  background-color: #3f51b5;
}
.pearl.current .pearl-number,
.pearl.current .pearl-icon {
  color: #3f51b5;
  background-color: #ffffff;
  border-color: #3f51b5;
  transform: scale(1.3);
}
.pearl.disabled {
  pointer-events: none;
  cursor: auto;
}
.pearl.disabled:before,
.pearl.disabled:after {
  background-color: #eeeeee;
}
.pearl.disabled .pearl-number,
.pearl.disabled .pearl-icon {
  color: #ffffff;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}
.pearl.error:before {
  background-color: #3f51b5;
}
.pearl.error:after {
  background-color: #eeeeee;
}
.pearl.error .pearl-number,
.pearl.error .pearl-icon {
  color: #f44336;
  background-color: #ffffff;
  border-color: #f44336;
}
.pearl.done:before,
.pearl.done:after {
  background-color: #3f51b5;
}
.pearl.done .pearl-number,
.pearl.done .pearl-icon {
  color: #ffffff;
  background-color: #3f51b5;
  border-color: #3f51b5;
}
.pearls-lg .pearl:before,
.pearls-lg .pearl:after {
  top: 20px;
}
.pearls-lg .pearl-title {
  font-size: 18px;
}
.pearls-lg .pearl-number,
.pearls-lg .pearl-icon {
  width: 40px;
  height: 40px;
  line-height: 36px;
}
.pearls-lg .pearl-icon {
  font-size: 20px;
}
.pearls-lg .pearl-number {
  font-size: 20px;
}
.pearls-sm .pearl:before,
.pearls-sm .pearl:after {
  top: 16px;
}
.pearls-sm .pearl-title {
  font-size: 14px;
}
.pearls-sm .pearl-number,
.pearls-sm .pearl-icon {
  width: 32px;
  height: 32px;
  line-height: 28px;
}
.pearls-sm .pearl-number {
  font-size: 16px;
}
.pearls-sm .pearl-icon {
  font-size: 14px;
}
.pearls-xs .pearl:before,
.pearls-xs .pearl:after {
  top: 12px;
  height: 2px;
}
.pearls-xs .pearl-title {
  font-size: 12px;
}
.pearls-xs .pearl-number,
.pearls-xs .pearl-icon {
  width: 24px;
  height: 24px;
  line-height: 20px;
}
.pearls-xs .pearl-number {
  font-size: 12px;
}
.pearls-xs .pearl-icon {
  font-size: 12px;
}
.timeline {
  position: relative;
  padding: 0;
  margin-bottom: 22px;
  list-style: none;
  background: transparent;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  margin-left: -1px;
  content: "";
  background-color: #e0e0e0;
}
.timeline:after {
  display: table;
  content: "";
}
.timeline:after {
  clear: both;
}
.timeline > li {
  position: relative;
  display: block;
  float: left;
  width: 50%;
  padding-right: 40px;
  margin-bottom: 60px;
}
.timeline > li:not(.timeline-period):before,
.timeline > li:not(.timeline-period):after {
  content: " ";
  display: table;
}
.timeline > li:not(.timeline-period):after {
  clear: both;
}
.timeline > li.timeline-period {
  position: relative;
  z-index: 6;
  display: block;
  float: none;
  width: 200px;
  padding: 25px 10px;
  margin: 20px auto 30px;
  clear: both;
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  background: #f3f4f5;
}
.timeline > li.timeline-reverse {
  float: right;
  padding-right: 0;
  padding-left: 40px;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline:not(.timeline-single) > li:not(.timeline-period):first-child + .timeline-item,
.timeline:not(.timeline-single) > li.timeline-period + .timeline-item + .timeline-item {
  margin-top: 90px;
}
.timeline-content {
  float: left;
  width: 100%;
  overflow: hidden;
  clear: left;
}
.timeline-reverse .timeline-content {
  float: right;
  clear: right;
}
.timeline-content > .widget {
  margin: 0;
}
.timeline-dot {
  position: absolute;
  right: 0;
  z-index: 11;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: #3f51b5;
  border-radius: 50%;
  top: 7.5px;
}
.timeline-reverse .timeline-dot {
  right: auto;
  left: 0;
  margin-right: 0;
}
.timeline .timeline-dot {
  width: 14px;
  height: 14px;
  margin-right: -7px;
  line-height: 14px;
}
.timeline .timeline-reverse .timeline-dot {
  margin-left: -7px;
}
.timeline.timeline-single {
  margin-left: 7px;
}
.timeline.timeline-single .timeline-dot {
  margin-left: -7px;
}
@media screen and (max-width: 767px) {
  .timeline {
    margin-left: 7px;
  }
  .timeline .timeline-dot {
    margin-left: -7px;
  }
}
.timeline-info {
  float: right;
  padding: 0 20px;
  margin-bottom: 22px;
  line-height: 28px;
  text-align: center;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
}
.timeline-reverse .timeline-info {
  float: left;
}
.timeline-footer {
  position: absolute;
  right: 0;
  bottom: -30px;
  margin-right: 55px;
}
.timeline-footer .icon {
  margin-right: .3em;
}
.timeline-reverse .timeline-footer {
  right: auto;
  left: 0;
  margin-right: 0;
  margin-left: 55px;
}
.timeline-reverse + .timeline-reverse {
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .timeline:before {
    left: 0;
  }
  .timeline > li,
  .timeline li.timeline-reverse {
    float: none;
    width: 100%;
    padding-right: 0;
    padding-left: 40px;
    margin-top: 0;
    margin-bottom: 60px;
  }
  .timeline-content {
    float: none;
  }
  .timeline-dot {
    right: auto;
    left: 0;
    margin-right: 0;
    margin-left: -7px;
  }
  .timeline-info {
    display: inline-block;
    float: none;
  }
  .timeline-footer {
    right: auto;
    bottom: -26px;
    left: 0;
    margin-right: 0;
    margin-left: 40px;
  }
}
.timeline-single:before {
  left: 0;
}
.timeline-single > li {
  float: none;
  width: 100%;
  padding-right: 0;
  padding-left: 40px;
  margin-bottom: 60px;
}
.timeline-single .timeline-content {
  float: none;
}
.timeline-single .timeline-dot {
  right: auto;
  left: 0;
  margin-right: 0;
  margin-left: -7px;
}
.timeline-single .timeline-info {
  float: left;
}
.timeline-single .timeline-footer {
  right: auto;
  bottom: -26px;
  left: 0;
  margin-right: 0;
  margin-left: 40px;
}
.timeline-icon .timeline-dot {
  width: 40px;
  height: 40px;
  margin-right: -20px;
  line-height: 40px;
}
.timeline-icon .timeline-reverse .timeline-dot {
  margin-left: -20px;
}
.timeline-icon.timeline-single {
  margin-left: 20px;
}
.timeline-icon.timeline-single .timeline-dot {
  margin-left: -20px;
}
@media screen and (max-width: 767px) {
  .timeline-icon {
    margin-left: 20px;
  }
  .timeline-icon .timeline-dot {
    margin-left: -20px;
  }
}
.timeline-icon .timeline-dot {
  top: -5.5px;
}
.timeline-avatar .timeline-dot {
  width: 40px;
  height: 40px;
  margin-right: -20px;
  line-height: 40px;
}
.timeline-avatar .timeline-reverse .timeline-dot {
  margin-left: -20px;
}
.timeline-avatar.timeline-single {
  margin-left: 20px;
}
.timeline-avatar.timeline-single .timeline-dot {
  margin-left: -20px;
}
@media screen and (max-width: 767px) {
  .timeline-avatar {
    margin-left: 20px;
  }
  .timeline-avatar .timeline-dot {
    margin-left: -20px;
  }
}
.timeline-avatar-sm .timeline-dot {
  width: 30px;
  height: 30px;
  margin-right: -15px;
  line-height: 30px;
}
.timeline-avatar-sm .timeline-reverse .timeline-dot {
  margin-left: -15px;
}
.timeline-avatar-sm.timeline-single {
  margin-left: 15px;
}
.timeline-avatar-sm.timeline-single .timeline-dot {
  margin-left: -15px;
}
@media screen and (max-width: 767px) {
  .timeline-avatar-sm {
    margin-left: 15px;
  }
  .timeline-avatar-sm .timeline-dot {
    margin-left: -15px;
  }
}
.timeline-avatar-lg .timeline-dot {
  width: 50px;
  height: 50px;
  margin-right: -25px;
  line-height: 50px;
}
.timeline-avatar-lg .timeline-reverse .timeline-dot {
  margin-left: -25px;
}
.timeline-avatar-lg.timeline-single {
  margin-left: 25px;
}
.timeline-avatar-lg.timeline-single .timeline-dot {
  margin-left: -25px;
}
@media screen and (max-width: 767px) {
  .timeline-avatar-lg {
    margin-left: 25px;
  }
  .timeline-avatar-lg .timeline-dot {
    margin-left: -25px;
  }
}
.timeline-simple .timeline-dot {
  top: 0;
  margin-top: 10px;
}
.timeline-feed .timeline-dot {
  width: 30px;
  height: 30px;
  margin-right: -15px;
  line-height: 30px;
}
.timeline-feed .timeline-reverse .timeline-dot {
  margin-left: -15px;
}
.timeline-feed.timeline-single {
  margin-left: 15px;
}
.timeline-feed.timeline-single .timeline-dot {
  margin-left: -15px;
}
@media screen and (max-width: 767px) {
  .timeline-feed {
    margin-left: 15px;
  }
  .timeline-feed .timeline-dot {
    margin-left: -15px;
  }
}
.timeline-feed.timeline-simple .timeline-dot {
  margin-top: 5px;
}
.timeline-feed > li {
  padding-right: 30px;
  margin-bottom: 22px;
}
.timeline-feed > li.timeline-reverse {
  padding-left: 30px;
}
.timeline-feed.timeline-single > li {
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  .timeline-feed > li {
    padding-right: 30px;
    margin-bottom: 22px;
  }
}
.testimonial {
  margin: 3px 3px 22px;
}
.testimonial-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.testimonial-item {
  float: left;
  padding: 0 15px 30px;
  margin: 0;
}
.testimonial-content {
  position: relative;
  padding: 15px 20px;
  margin-top: 10px;
  margin-bottom: 25px;
  background-color: #eeeeee;
  border-radius: 3px;
}
.testimonial-content:before {
  position: absolute;
  bottom: -7px;
  left: 33px;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  background-color: #eeeeee;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.testimonial-content > p:last-child {
  margin-bottom: 0;
}
.testimonial-image {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 20px;
}
.testimonial-author {
  display: block;
  margin-left: 75px;
  font-size: 18px;
}
.testimonial-company {
  display: block;
  margin-left: 75px;
  font-size: 12px;
  opacity: .8;
}
.testimonial-control a {
  color: #bdbdbd;
  font-size: 26px;
}
.testimonial-control a:hover {
  color: #3f51b5;
  text-decoration: none;
}
.testimonial-reverse .testimonial-content:before {
  right: 33px;
  left: auto;
}
.testimonial-reverse .testimonial-image {
  float: right;
  margin-right: 20px;
  margin-left: 0;
}
.testimonial-reverse .testimonial-author,
.testimonial-reverse .testimonial-company {
  margin-left: 0;
  margin-right: 75px;
  text-align: right;
}
.testimonial-top .testimonial-item {
  padding: 30px 15px 0;
}
.testimonial-top .testimonial-content {
  margin-top: 30px;
  margin-bottom: 10px;
}
.testimonial-top .testimonial-content:before {
  top: -7px;
  bottom: auto;
}
.testimonial.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.testimonial.carousel .testimonial-item {
  position: relative;
  display: none;
  width: 100%;
  padding: 10px;
  transition: left 0.6s ease-in-out 0s;
}
.testimonial.carousel .testimonial-item.active,
.testimonial.carousel .testimonial-item.next,
.testimonial.carousel .testimonial-item.prev {
  display: block;
}
.testimonial.carousel .testimonial-item.next,
.testimonial.carousel .testimonial-item.prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.testimonial.carousel .testimonial-item.next {
  left: 100%;
}
.testimonial.carousel .testimonial-item.prev {
  left: -100%;
}
.testimonial.carousel .testimonial-item.next.left,
.testimonial.carousel .testimonial-item.prev.right {
  left: 0;
}
.testimonial.carousel .testimonial-item.active {
  left: 0;
}
.testimonial.carousel .testimonial-item.active.left {
  left: -100%;
}
.testimonial.carousel .testimonial-item.active.right {
  left: 100%;
}
.testimonial.carousel .testimonial-content {
  padding: 10px;
}
.testimonial.carousel .testimonial-control {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
.testimonial.carousel .testimonial-control > * {
  margin-left: 10px;
}
.testimonial.carousel.testimonial-reverse .testimonial-control {
  right: auto;
  left: 10px;
}
.testimonial.carousel.testimonial-reverse .testimonial-control > * {
  margin-right: 10px;
  margin-left: 0;
}
.testimonial.carousel.testimonial-top .testimonial-control {
  top: 20px;
  bottom: auto;
}
.pricing-list {
  margin-bottom: 22px;
  text-align: center;
  -webkit-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.pricing-list [class^="bg-"],
.pricing-list [class*="bg-"],
.pricing-list [class^="bg-"] *,
.pricing-list [class*="bg-"] * {
  color: #ffffff;
}
.pricing-list .pricing-header {
  border-bottom: 1px solid #e0e0e0;
  border-radius: 3px 3px 0 0;
}
.pricing-list .pricing-title {
  padding: 15px 30px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 3px 3px 0 0;
}
.pricing-list .pricing-price {
  padding: 20px 30px;
  margin: 0;
  font-size: 54px;
  font-weight: 500;
  color: #424242;
}
.pricing-list .pricing-period {
  font-size: 14px;
  font-weight: 300;
}
.pricing-list .pricing-features {
  padding: 0 18px;
  margin: 0;
}
.pricing-list .pricing-features li {
  display: block;
  padding: 15px;
  list-style: none;
  border-top: 1px dashed #e0e0e0;
}
.pricing-list .pricing-features li:first-child {
  border-top: none;
}
.pricing-list .pricing-footer {
  padding: 30px;
  border-radius: 0 0 3px 3px;
}
.pricing-table {
  padding-top: 30px;
  text-align: center;
}
.pricing-table:before,
.pricing-table:after {
  content: " ";
  display: table;
}
.pricing-table:after {
  clear: both;
}
.pricing-table [class*="pricing-column"] {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  background-color: #eeeeee;
  border: 1px solid #e0e0e0;
  border-right: none;
}
.pricing-table [class*="pricing-column"]:last-child {
  border-right: 1px solid #e0e0e0;
}
.pricing-table [class*="pricing-column"].featured {
  position: relative;
  margin-right: -1px;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
}
.pricing-table .pricing-header {
  padding-bottom: 24px;
  margin: 30px 30px 25px;
  border-bottom: 1px solid #e0e0e0;
}
.pricing-table .pricing-price {
  font-size: 48px;
}
.pricing-table .pricing-currency {
  display: inline-block;
  margin-top: 10px;
  margin-right: -10px;
  font-size: 20px;
  vertical-align: top;
}
.pricing-table .pricing-period {
  font-size: 16px;
}
.pricing-table .pricing-title {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.pricing-table .pricing-features {
  padding: 0;
  margin: 0;
}
.pricing-table .pricing-features li {
  display: block;
  margin-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.pricing-table .pricing-footer {
  padding: 20px 0;
  margin: 25px 30px 30px;
}
@media screen and (min-width: 768px) {
  .pricing-table .pricing-column-three {
    width: 33.33%;
  }
  .pricing-table .pricing-column-three.featured {
    top: -30px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -30px;
  }
  .pricing-table .pricing-column-four {
    width: 50%;
  }
  .pricing-table .pricing-column-five {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .pricing-table .pricing-column-four {
    width: 25%;
  }
  .pricing-table .pricing-column-five {
    width: 20%;
  }
  .pricing-table .pricing-column-four.featured,
  .pricing-table .pricing-column-five.featured {
    top: -30px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -30px;
  }
}
.rating {
  display: inline-block;
  margin: 0 .5rem 0 0;
  font-size: 0;
  vertical-align: middle;
}
.rating:before {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  content: '';
}
.rating.hover .icon.active {
  opacity: .5;
}
.rating .icon {
  width: 1em;
  height: auto;
  padding: 0;
  margin: 0 10px 0 0;
  font-size: 14px;
  color: #bdbdbd;
  vertical-align: middle;
  cursor: pointer;
}
.rating .icon:before {
  transition: color .3s ease,
      opacity .3s ease;
}
.rating .icon.active {
  color: #fb8c00 !important;
}
.rating .icon.active.hover {
  color: #fb8c00 !important;
  opacity: 1;
}
.rating .icon.hover {
  color: #fb8c00 !important;
  opacity: 1;
}
.rating .icon:last-child {
  margin-right: 0;
}
.rating-disabled .icon {
  cursor: default;
}
.rating-sm .icon {
  font-size: 12px;
}
.rating-lg .icon {
  font-size: 18px;
}
.ribbon {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 150px;
  height: 150px;
  text-align: center;
  background-color: transparent;
}
.ribbon-inner {
  position: absolute;
  top: 16px;
  left: 0;
  display: inline-block;
  height: 30px;
  padding-right: 20px;
  padding-left: 20px;
  line-height: 30px;
  color: #ffffff;
  white-space: nowrap;
  background-color: #616161;
}
.ribbon-inner .icon {
  font-size: 16px;
}
.ribbon-lg .ribbon-inner {
  height: 38px;
  font-size: 18px;
  line-height: 38px;
}
.ribbon-sm .ribbon-inner {
  height: 26px;
  font-size: 12px;
  line-height: 26px;
}
.ribbon-xs .ribbon-inner {
  height: 22px;
  font-size: 12px;
  line-height: 22px;
}
.ribbon-vertical .ribbon-inner {
  top: 0;
  left: 16px;
  width: 30px;
  height: 60px;
  padding: 15px 0;
}
.ribbon-vertical.ribbon-xs .ribbon-inner {
  width: 22px;
  height: 50px;
}
.ribbon-vertical.ribbon-sm .ribbon-inner {
  width: 26px;
  height: 55px;
}
.ribbon-vertical.ribbon-lg .ribbon-inner {
  width: 38px;
  height: 70px;
}
.ribbon-reverse {
  right: -3px;
  left: auto;
}
.ribbon-reverse .ribbon-inner {
  right: 0;
  left: auto;
}
.ribbon-reverse.ribbon-vertical .ribbon-inner {
  right: 16px;
}
.ribbon-bookmark .ribbon-inner {
  box-shadow: none;
}
.ribbon-bookmark .ribbon-inner:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border: 15px solid #616161;
  border-right: 10px solid transparent;
}
.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  top: 100%;
  left: 0;
  margin-top: -15px;
  border-right: 15px solid #616161;
  border-bottom: 10px solid transparent;
}
.ribbon-bookmark.ribbon-vertical.ribbon-xs .ribbon-inner:before {
  margin-top: -11px;
}
.ribbon-bookmark.ribbon-vertical.ribbon-sm .ribbon-inner:before {
  margin-top: -13px;
}
.ribbon-bookmark.ribbon-vertical.ribbon-lg .ribbon-inner:before {
  margin-top: -19px;
}
.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #616161;
  border-left: 10px solid transparent;
}
.ribbon-bookmark.ribbon-reverse.ribbon-vertical .ribbon-inner:before {
  right: auto;
  left: 0;
  border-right-color: #616161;
  border-bottom-color: transparent;
  border-left: 15px solid #616161;
}
.ribbon-bookmark.ribbon-xs .ribbon-inner:before {
  border-width: 11px;
}
.ribbon-bookmark.ribbon-sm .ribbon-inner:before {
  border-width: 13px;
}
.ribbon-bookmark.ribbon-lg .ribbon-inner:before {
  border-width: 19px;
}
.ribbon-badge {
  top: -2px;
  left: -2px;
  overflow: hidden;
}
.ribbon-badge .ribbon-inner {
  left: -40px;
  width: 100%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.ribbon-badge.ribbon-reverse {
  right: -2px;
  left: auto;
}
.ribbon-badge.ribbon-reverse .ribbon-inner {
  right: -40px;
  left: auto;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: -2px;
}
.ribbon-badge.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 16px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ribbon-badge.ribbon-bottom.ribbon-reverse .ribbon-inner {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.ribbon-corner {
  top: 0;
  left: 0;
  overflow: hidden;
}
.ribbon-corner .ribbon-inner {
  top: 0;
  left: 0;
  width: 40px;
  height: 35px;
  padding: 0;
  line-height: 35px;
  background-color: transparent;
}
.ribbon-corner .ribbon-inner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 30px solid transparent;
  border-top-color: #616161;
  border-left-color: #616161;
}
.ribbon-corner.ribbon-reverse {
  right: 0;
  left: auto;
}
.ribbon-corner.ribbon-reverse .ribbon-inner {
  right: 0;
  left: auto;
}
.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  right: 0;
  left: auto;
  border-right-color: #616161;
  border-left-color: transparent;
}
.ribbon-corner.ribbon-bottom {
  top: auto;
  bottom: 0;
}
.ribbon-corner.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 0;
}
.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  top: auto;
  bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #616161;
}
.ribbon-corner.ribbon-xs .ribbon-inner {
  width: 28px;
  height: 26px;
  line-height: 26px;
}
.ribbon-corner.ribbon-xs .ribbon-inner:before {
  border-width: 22px;
}
.ribbon-corner.ribbon-xs .ribbon-inner > .icon {
  font-size: 12px;
}
.ribbon-corner.ribbon-sm .ribbon-inner {
  width: 34px;
  height: 32px;
  line-height: 32px;
}
.ribbon-corner.ribbon-sm .ribbon-inner:before {
  border-width: 26px;
}
.ribbon-corner.ribbon-sm .ribbon-inner > .icon {
  font-size: 12px;
}
.ribbon-corner.ribbon-lg .ribbon-inner {
  width: 46px;
  height: 44px;
  line-height: 44px;
}
.ribbon-corner.ribbon-lg .ribbon-inner:before {
  border-width: 36px;
}
.ribbon-corner.ribbon-lg .ribbon-inner > .icon {
  font-size: 18px;
}
.ribbon-clip {
  left: -14px;
}
.ribbon-clip .ribbon-inner {
  padding-left: 23px;
  border-radius: 0 5px 5px 0;
}
.ribbon-clip .ribbon-inner:after {
  position: absolute;
  bottom: -14px;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 7px solid transparent;
  border-top-color: #424242;
  border-right-color: #424242;
}
.ribbon-clip.ribbon-reverse {
  right: -14px;
  left: auto;
}
.ribbon-clip.ribbon-reverse .ribbon-inner {
  padding-right: 23px;
  padding-left: 15px;
  border-radius: 5px 0 0 5px;
}
.ribbon-clip.ribbon-reverse .ribbon-inner:after {
  right: 0;
  left: auto;
  border-right-color: transparent;
  border-left-color: #424242;
}
.ribbon-clip.ribbon-bottom {
  top: auto;
  bottom: -3px;
}
.ribbon-clip.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 16px;
}
.ribbon-clip.ribbon-bottom .ribbon-inner:after {
  top: -14px;
  bottom: auto;
  border-top-color: transparent;
  border-bottom-color: #424242;
}
.ribbon-primary .ribbon-inner {
  background-color: #3f51b5;
}
.ribbon-primary.ribbon-bookmark .ribbon-inner:before {
  border-color: #3f51b5;
  border-right-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #3f51b5;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #3f51b5;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #3f51b5;
  border-bottom-color: transparent;
  border-left-color: #3f51b5;
}
.ribbon-primary.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-primary.ribbon-corner .ribbon-inner:before {
  border-top-color: #3f51b5;
  border-left-color: #3f51b5;
}
.ribbon-primary.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #3f51b5;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #3f51b5;
}
.ribbon-primary .ribbon-inner:after {
  border-top-color: #3949ab;
  border-right-color: #3949ab;
}
.ribbon-primary.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #3949ab;
}
.ribbon-primary.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #3949ab;
}
.ribbon-success .ribbon-inner {
  background-color: #4caf50;
}
.ribbon-success.ribbon-bookmark .ribbon-inner:before {
  border-color: #4caf50;
  border-right-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #4caf50;
  border-left-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #4caf50;
  border-bottom-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #4caf50;
  border-bottom-color: transparent;
  border-left-color: #4caf50;
}
.ribbon-success.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-success.ribbon-corner .ribbon-inner:before {
  border-top-color: #4caf50;
  border-left-color: #4caf50;
}
.ribbon-success.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #4caf50;
  border-left-color: transparent;
}
.ribbon-success.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #4caf50;
}
.ribbon-success .ribbon-inner:after {
  border-top-color: #43a047;
  border-right-color: #43a047;
}
.ribbon-success.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #43a047;
}
.ribbon-success.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #43a047;
}
.ribbon-info .ribbon-inner {
  background-color: #00bcd4;
}
.ribbon-info.ribbon-bookmark .ribbon-inner:before {
  border-color: #00bcd4;
  border-right-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-left-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-bottom-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-bottom-color: transparent;
  border-left-color: #00bcd4;
}
.ribbon-info.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-info.ribbon-corner .ribbon-inner:before {
  border-top-color: #00bcd4;
  border-left-color: #00bcd4;
}
.ribbon-info.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #00bcd4;
  border-left-color: transparent;
}
.ribbon-info.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #00bcd4;
}
.ribbon-info .ribbon-inner:after {
  border-top-color: #00acc1;
  border-right-color: #00acc1;
}
.ribbon-info.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #00acc1;
}
.ribbon-info.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #00acc1;
}
.ribbon-warning .ribbon-inner {
  background-color: #ff9800;
}
.ribbon-warning.ribbon-bookmark .ribbon-inner:before {
  border-color: #ff9800;
  border-right-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #ff9800;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #ff9800;
  border-bottom-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #ff9800;
  border-bottom-color: transparent;
  border-left-color: #ff9800;
}
.ribbon-warning.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-warning.ribbon-corner .ribbon-inner:before {
  border-top-color: #ff9800;
  border-left-color: #ff9800;
}
.ribbon-warning.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #ff9800;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #ff9800;
}
.ribbon-warning .ribbon-inner:after {
  border-top-color: #fb8c00;
  border-right-color: #fb8c00;
}
.ribbon-warning.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #fb8c00;
}
.ribbon-warning.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #fb8c00;
}
.ribbon-danger .ribbon-inner {
  background-color: #f44336;
}
.ribbon-danger.ribbon-bookmark .ribbon-inner:before {
  border-color: #f44336;
  border-right-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f44336;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #f44336;
  border-bottom-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f44336;
  border-bottom-color: transparent;
  border-left-color: #f44336;
}
.ribbon-danger.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-danger.ribbon-corner .ribbon-inner:before {
  border-top-color: #f44336;
  border-left-color: #f44336;
}
.ribbon-danger.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f44336;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #f44336;
}
.ribbon-danger .ribbon-inner:after {
  border-top-color: #e53935;
  border-right-color: #e53935;
}
.ribbon-danger.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #e53935;
}
.ribbon-danger.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #e53935;
}
.dotnav {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
}
.dotnav > li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 16px;
  vertical-align: top;
  list-style: none;
}
.dotnav > li > a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}
.dotnav > li > a:focus {
  outline: none;
}
.dotnav > li.active > a,
.dotnav > li:hover > a,
.dotnav > li:focus > a {
  background-color: #ffffff;
}
.dotnav-scaleup > li > a {
  transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
}
.dotnav-scaleup > li.active > a {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}
.dotnav-stroke > li > a {
  border: 2px solid transparent;
  transition: border 0.3s ease 0s, background-color 0.3s ease 0s;
}
.dotnav-stroke > li.active > a {
  background-color: transparent;
  border-color: #ffffff;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}
.dotnav-fillin > li > a {
  background-color: transparent;
  box-shadow: 0 0 0 2px #fff inset;
  transition: box-shadow 0.3s ease 0s;
}
.dotnav-fillin > li:hover > a,
.dotnav-fillin > li:focus > a {
  background-color: transparent;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.6) inset;
}
.dotnav-fillin > li.active > a {
  box-shadow: 0 0 0 8px #fff inset;
}
.dotnav-dotstroke > li > a {
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5) inset;
  transition: box-shadow 0.3s ease 0s;
}
.dotnav-dotstroke > li.active > a {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 2px #fff inset;
}
.dotnav-fall > li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  opacity: 0;
  position: absolute;
  -webkit-transform: translate(0%, -200%);
  -ms-transform: translate(0%, -200%);
  -o-transform: translate(0%, -200%);
  transform: translate(0%, -200%);
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
}
.dotnav-fall > li > a {
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, background-color 0.3s ease 0s;
}
.dotnav-fall > li.active:after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  -o-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
}
.dotnav-fall > li.active > a {
  opacity: 0;
  -webkit-transform: translate(0, 200%);
  -ms-transform: translate(0, 200%);
  -o-transform: translate(0, 200%);
  transform: translate(0, 200%);
}
.color-selector {
  padding: 0;
  margin: 0;
  list-style: none;
}
.color-selector > li {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 8px 8px 0;
  background-color: #3f51b5;
  border-radius: 100%;
}
.color-selector > li:hover {
  opacity: .8;
}
.color-selector > li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: inherit;
  height: inherit;
  content: "";
  background: inherit;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: inherit;
}
.color-selector > li input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: inherit;
  height: inherit;
  cursor: pointer;
  border-radius: inherit;
  opacity: 0;
}
.color-selector > li input[type="radio"]:disabled {
  cursor: not-allowed;
}
.color-selector > li label {
  position: relative;
  font-family: "Material-Design-Iconic-Font";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}
.color-selector > li input[type="radio"]:checked + label:after {
  position: absolute;
  top: 0;
  left: 8px;
  display: inline-block;
  margin-top: -2px;
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
  content: '\f26b';
}
.color-selector > li.color-selector-disabled {
  background-color: #bdbdbd !important;
}
.color-selector > li.color-selector-disabled input[type="radio"]:disabled {
  cursor: not-allowed;
}
.width-50 {
  width: 50px;
}
.width-100 {
  width: 100px;
}
.width-150 {
  width: 150px;
}
.width-200 {
  width: 200px;
}
.width-250 {
  width: 250px;
}
.width-300 {
  width: 300px;
}
.width-350 {
  width: 350px;
}
.width-400 {
  width: 400px;
}
.width-450 {
  width: 450px;
}
.width-500 {
  width: 500px;
}
.width-full {
  width: 100% !important;
}
@media (max-width: 767px) {
  .width-xs-50 {
    width: 50px;
  }
  .width-xs-100 {
    width: 100px;
  }
  .width-xs-150 {
    width: 150px;
  }
  .width-xs-200 {
    width: 200px;
  }
  .width-xs-250 {
    width: 250px;
  }
  .width-xs-300 {
    width: 300px;
  }
  .width-xs-350 {
    width: 350px;
  }
  .width-xs-400 {
    width: 400px;
  }
  .width-xs-450 {
    width: 450px;
  }
  .width-xs-500 {
    width: 500px;
  }
  .width-xs-100pc {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .width-sm-50 {
    width: 50px;
  }
  .width-sm-100 {
    width: 100px;
  }
  .width-sm-150 {
    width: 150px;
  }
  .width-sm-200 {
    width: 200px;
  }
  .width-sm-250 {
    width: 250px;
  }
  .width-sm-300 {
    width: 300px;
  }
  .width-sm-350 {
    width: 350px;
  }
  .width-sm-400 {
    width: 400px;
  }
  .width-sm-450 {
    width: 450px;
  }
  .width-sm-500 {
    width: 500px;
  }
  .width-sm-100pc {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .width-md-50 {
    width: 50px;
  }
  .width-md-100 {
    width: 100px;
  }
  .width-md-150 {
    width: 150px;
  }
  .width-md-200 {
    width: 200px;
  }
  .width-md-250 {
    width: 250px;
  }
  .width-md-300 {
    width: 300px;
  }
  .width-md-350 {
    width: 350px;
  }
  .width-md-400 {
    width: 400px;
  }
  .width-md-450 {
    width: 450px;
  }
  .width-md-500 {
    width: 500px;
  }
}
@media (min-width: 1200px) {
  .width-lg-50 {
    width: 50px;
  }
  .width-lg-100 {
    width: 100px;
  }
  .width-lg-150 {
    width: 150px;
  }
  .width-lg-200 {
    width: 200px;
  }
  .width-lg-250 {
    width: 250px;
  }
  .width-lg-300 {
    width: 300px;
  }
  .width-lg-350 {
    width: 350px;
  }
  .width-lg-400 {
    width: 400px;
  }
  .width-lg-450 {
    width: 450px;
  }
  .width-lg-500 {
    width: 500px;
  }
}
.height-50 {
  height: 50px;
}
.height-100 {
  height: 100px;
}
.height-120 {
  height: 120px;
}
.height-150 {
  height: 150px;
}
.height-200 {
  height: 200px;
}
.height-250 {
  height: 250px;
}
.height-300 {
  height: 300px;
}
.height-350 {
  height: 350px;
}
.height-400 {
  height: 400px;
}
.height-450 {
  height: 450px;
}
.height-500 {
  height: 500px;
}
.height-full {
  height: 100% !important;
}
@media (max-width: 767px) {
  .height-xs-50 {
    height: 50px;
  }
  .height-xs-100 {
    height: 100px;
  }
  .height-xs-120 {
    height: 120px;
  }
  .height-xs-150 {
    height: 150px;
  }
  .height-xs-200 {
    height: 200px;
  }
  .height-xs-250 {
    height: 250px;
  }
  .height-xs-300 {
    height: 300px;
  }
  .height-xs-350 {
    height: 350px;
  }
  .height-xs-400 {
    height: 400px;
  }
  .height-xs-450 {
    height: 450px;
  }
  .height-xs-500 {
    height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .height-sm-50 {
    height: 50px;
  }
  .height-sm-100 {
    height: 100px;
  }
  .height-sm-120 {
    height: 120px;
  }
  .height-sm-150 {
    height: 150px;
  }
  .height-sm-200 {
    height: 200px;
  }
  .height-sm-250 {
    height: 250px;
  }
  .height-sm-300 {
    height: 300px;
  }
  .height-sm-350 {
    height: 350px;
  }
  .height-sm-400 {
    height: 400px;
  }
  .height-sm-450 {
    height: 450px;
  }
  .height-sm-500 {
    height: 500px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .height-md-50 {
    height: 50px;
  }
  .height-md-100 {
    height: 100px;
  }
  .height-md-120 {
    height: 120px;
  }
  .height-md-150 {
    height: 150px;
  }
  .height-md-200 {
    height: 200px;
  }
  .height-md-250 {
    height: 250px;
  }
  .height-md-300 {
    height: 300px;
  }
  .height-md-350 {
    height: 350px;
  }
  .height-md-400 {
    height: 400px;
  }
  .height-md-450 {
    height: 450px;
  }
  .height-md-500 {
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .height-lg-50 {
    height: 50px;
  }
  .height-lg-100 {
    height: 100px;
  }
  .height-lg-120 {
    height: 120px;
  }
  .height-lg-150 {
    height: 150px;
  }
  .height-lg-200 {
    height: 200px;
  }
  .height-lg-250 {
    height: 250px;
  }
  .height-lg-300 {
    height: 300px;
  }
  .height-lg-350 {
    height: 350px;
  }
  .height-lg-400 {
    height: 400px;
  }
  .height-lg-450 {
    height: 450px;
  }
  .height-lg-500 {
    height: 500px;
  }
}
.margin-0 {
  margin: 0 !important;
}
.margin-3 {
  margin: 3px !important;
}
.margin-5 {
  margin: 5px !important;
}
.margin-10 {
  margin: 10px !important;
}
.margin-15 {
  margin: 15px !important;
}
.margin-20 {
  margin: 20px !important;
}
.margin-25 {
  margin: 25px !important;
}
.margin-30 {
  margin: 30px !important;
}
.margin-35 {
  margin: 35px !important;
}
.margin-40 {
  margin: 40px !important;
}
.margin-45 {
  margin: 45px !important;
}
.margin-50 {
  margin: 50px !important;
}
.margin-60 {
  margin: 60px !important;
}
.margin-70 {
  margin: 70px !important;
}
.margin-80 {
  margin: 80px !important;
}
.margin-vertical-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin-vertical-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.margin-vertical-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.margin-vertical-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.margin-vertical-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.margin-vertical-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.margin-vertical-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.margin-vertical-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.margin-vertical-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
.margin-vertical-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.margin-vertical-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
.margin-vertical-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.margin-vertical-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.margin-vertical-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.margin-vertical-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.margin-horizontal-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.margin-horizontal-3 {
  margin-right: 3px !important;
  margin-left: 3px !important;
}
.margin-horizontal-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.margin-horizontal-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.margin-horizontal-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.margin-horizontal-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.margin-horizontal-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}
.margin-horizontal-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}
.margin-horizontal-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}
.margin-horizontal-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}
.margin-horizontal-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}
.margin-horizontal-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}
.margin-horizontal-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}
.margin-horizontal-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}
.margin-horizontal-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.margin-top-3 {
  margin-top: 3px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-top-35 {
  margin-top: 35px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-top-45 {
  margin-top: 45px !important;
}
.margin-top-50 {
  margin-top: 50px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}
.margin-top-80 {
  margin-top: 80px !important;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-bottom-3 {
  margin-bottom: 3px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-left-3 {
  margin-left: 3px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-35 {
  margin-left: 35px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-left-45 {
  margin-left: 45px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.margin-left-60 {
  margin-left: 60px !important;
}
.margin-left-70 {
  margin-left: 70px !important;
}
.margin-left-80 {
  margin-left: 80px !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-right-3 {
  margin-right: 3px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-35 {
  margin-right: 35px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-right-45 {
  margin-right: 45px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-right-60 {
  margin-right: 60px !important;
}
.margin-right-70 {
  margin-right: 70px !important;
}
.margin-right-80 {
  margin-right: 80px !important;
}
@media (max-width: 767px) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) {
  .margin-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}
.padding-0 {
  padding: 0 !important;
}
.padding-3 {
  padding: 3px !important;
}
.padding-5 {
  padding: 5px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-15 {
  padding: 15px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-25 {
  padding: 25px !important;
}
.padding-30 {
  padding: 30px !important;
}
.padding-35 {
  padding: 35px !important;
}
.padding-40 {
  padding: 40px !important;
}
.padding-45 {
  padding: 45px !important;
}
.padding-50 {
  padding: 50px !important;
}
.padding-60 {
  padding: 60px !important;
}
.padding-70 {
  padding: 70px !important;
}
.padding-80 {
  padding: 80px !important;
}
.padding-vertical-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.padding-vertical-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.padding-vertical-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.padding-vertical-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.padding-vertical-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.padding-vertical-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.padding-vertical-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.padding-vertical-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.padding-vertical-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.padding-vertical-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.padding-vertical-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.padding-vertical-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.padding-vertical-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.padding-vertical-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.padding-vertical-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.padding-horizontal-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.padding-horizontal-3 {
  padding-right: 3px !important;
  padding-left: 3px !important;
}
.padding-horizontal-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.padding-horizontal-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.padding-horizontal-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.padding-horizontal-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.padding-horizontal-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.padding-horizontal-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.padding-horizontal-35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.padding-horizontal-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.padding-horizontal-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}
.padding-horizontal-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}
.padding-horizontal-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}
.padding-horizontal-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}
.padding-horizontal-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.padding-top-3 {
  padding-top: 3px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-top-35 {
  padding-top: 35px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.padding-top-45 {
  padding-top: 45px !important;
}
.padding-top-50 {
  padding-top: 50px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}
.padding-top-70 {
  padding-top: 70px !important;
}
.padding-top-80 {
  padding-top: 80px !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-bottom-3 {
  padding-bottom: 3px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-bottom-30 {
  padding-bottom: 30px !important;
}
.padding-bottom-35 {
  padding-bottom: 35px !important;
}
.padding-bottom-40 {
  padding-bottom: 40px !important;
}
.padding-bottom-45 {
  padding-bottom: 45px !important;
}
.padding-bottom-50 {
  padding-bottom: 50px !important;
}
.padding-bottom-60 {
  padding-bottom: 60px !important;
}
.padding-bottom-70 {
  padding-bottom: 70px !important;
}
.padding-bottom-80 {
  padding-bottom: 80px !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-left-3 {
  padding-left: 3px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-left-35 {
  padding-left: 35px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}
.padding-left-45 {
  padding-left: 45px !important;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.padding-left-60 {
  padding-left: 60px !important;
}
.padding-left-70 {
  padding-left: 70px !important;
}
.padding-left-80 {
  padding-left: 80px !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-3 {
  padding-right: 3px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-right-35 {
  padding-right: 35px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}
.padding-right-45 {
  padding-right: 45px !important;
}
.padding-right-50 {
  padding-right: 50px !important;
}
.padding-right-60 {
  padding-right: 60px !important;
}
.padding-right-70 {
  padding-right: 70px !important;
}
.padding-right-80 {
  padding-right: 80px !important;
}
@media (max-width: 767px) {
  .padding-xs-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) {
  .padding-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) {
  .padding-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) {
  .padding-lg-0 {
    padding: 0 !important;
  }
}
.example-wrap {
  margin-bottom: 80px;
}
.example-wrap .example-wrap {
  margin-bottom: 0;
}
.example {
  margin-top: 20px;
  margin-bottom: 20px;
}
.example:before,
.example:after {
  content: " ";
  display: table;
}
.example:after {
  clear: both;
}
.example-title {
  text-transform: uppercase;
}
.example-title,
h4.example-title {
  font-size: 14px;
}
.panel-body > .example-wrap:last-child {
  margin-bottom: 0;
}
.panel-body > .row:last-child > [class*="col-"]:last-child .example-wrap:last-child {
  margin-bottom: 0;
}
.example-well {
  position: relative;
  margin-bottom: 30px;
  background-color: #eceff1;
}
.example-well .center {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.example-dropdown .dropdown:before,
.example-dropdown .dropup:before,
.example-dropdown .dropdown:after,
.example-dropdown .dropup:after {
  content: " ";
  display: table;
}
.example-dropdown .dropdown:after,
.example-dropdown .dropup:after {
  clear: both;
}
.example-dropdown .dropdown > .dropdown-toggle,
.example-dropdown .dropup > .dropdown-toggle {
  float: left;
}
.example-dropdown .dropdown > .dropdown-menu,
.example-dropdown .dropup > .dropdown-menu {
  position: static;
  display: block;
  clear: left;
}
.example-dropdown .dropdown > .dropdown-menu-right,
.example-dropdown .dropup > .dropdown-menu-right {
  float: right;
  clear: right;
}
.example-tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.example-tooltip .tooltip {
  position: relative;
  margin-right: 25px;
  opacity: 1;
}
.example-grid .example-col,
.example-blocks .example-col {
  min-height: 0;
  padding: 10px 15px 12px;
  background-color: #eceff1;
  border-radius: 0;
}
.example-grid .example-col {
  margin-bottom: 20px;
}
.example-grid .example-col .example-col {
  margin-top: 20px;
  margin-bottom: 0;
  background-color: #dde3e6;
}
.example-popover {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.example-popover .popover {
  position: relative;
  display: block;
  margin-right: 25px;
}
.example-buttons .btn,
.example-buttons .btn-group,
.example-buttons .btn-group-vertical {
  margin-right: 15px;
  margin-bottom: 20px;
}
.example-buttons .btn-group-vertical .btn,
.example-buttons .btn-group .btn,
.example-buttons .btn-group-vertical .btn-group,
.example-buttons .btn-group .btn-group,
.example-buttons .btn-group-vertical .btn-group-vertical,
.example-buttons .btn-group .btn-group-vertical {
  margin-right: 0;
  margin-bottom: 0;
}
.example-box {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding: 45px 15px 15px;
  border: 1px solid #e0e0e0;
}
.example-box:after {
  position: absolute;
  top: 15px;
  left: 15px;
  content: "Example";
  color: #959595;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.example-avatars .avatar {
  margin-right: 20px;
  margin-bottom: 20px;
}
.example-avatars .avatar:last-child {
  margin-right: 20px;
}
.example-typography {
  position: relative;
  padding-left: 25%;
}
.example-typography .heading-note,
.example-typography .text-note {
  position: absolute;
  bottom: 2px;
  left: 0;
  display: block;
  width: 260px;
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
  color: #aab2bd;
}
.example-typography .text-note {
  top: 10px;
  bottom: auto;
}
.example-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media screen and (max-width: 767px) {
  .example-responsive {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
